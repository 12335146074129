<div id="card-login" class="d-flex align-items-center justify-content-center shadow-sm">
  <div>
    <div>
      <div class="text-center mt-1 mb-4">
        <!--Logo-->
        <h1 class="titulo">{{ primeiroAcesso ? 'Definição de senha' : 'Redefinição de senha' }}</h1>
        <h6 class="subtitulo ">
          Crie uma nova senha para sua conta
        </h6>
      </div>
      <div *ngIf="usuarios.length > 1" class="mb-6 text-start">
        <label class="subtitulo">Grupos</label><br />
        <ng-select [items]="usuarios" bindLabel="nomeGrupo" bindValue="codigoUsuario" multiple="true"
                   [(ngModel)]="codigosUsuarios" class="custom-ng-select"></ng-select>
      </div>
      <br />
      <div class="my-6 text-start">
        <label class="subtitulo">Nova senha </label><br />
        <input name="senha" class="caixa-texto" type="password" (keyup)="onkeyUp($event)"
               [(ngModel)]="senha" maxlength="32" />
      </div>
      <div class="mb-6 text-start">
        <label class="subtitulo">Repetir a nova senha </label><br />
        <input name="senha" class="caixa-texto" type="password" (keyup)="onkeyUp($event)"
               [(ngModel)]="confSenha" maxlength="32" />
      </div>
      <div class="my-3 text-center">
        <button class="btn btn-primary btn-md btn-block" type="button" (click)="alterarSenha();" #btn>
          Salvar senha
        </button>
      </div>
    </div>
  </div>
</div>
