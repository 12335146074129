<div class="row">
  <div class="col-md-3">
    <app-input [(ngModel)]="item.codigo"
               placeholder="Código"
               [title]="'Código'"
               [mask]="'000'" />
  </div>
  <div class="col-md-9">
    <app-input [(ngModel)]="item.descricao"
               placeholder="Descrição"
               [title]="'Descrição'" />
  </div>
</div>
