import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css']
})
export class BreadCrumbComponent implements OnInit {
  breadcrumb: string[] = [];

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.data['value'].breadcrumb)
      this.breadcrumb = this.activatedRoute.data['value'].breadcrumb.split('|');
  }
}
