<ng-template #modalRecuperarSenha let-c="close" let-d="dismiss">
  <div class="modal-header background-secondary">
    <h4 class="modal-title text-white">{{ primeiroAcesso ? 'Primeiro acesso?' : 'Esqueceu a senha?' }}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="c();">
    </button>
  </div>
  <div class="modal-body">
    <form #frmRecuperarSenha="ngForm" class="needs-validation">
      <div>
        <p class="mb-4">
          Informe o e-mail ou celular cadastrado na sua conta e lhe enviaremos uma mensagem com instruções para cadastro
          de uma nova senha.
        </p>
        <app-input type="text" title="Endereço de e-mail ou celular" name="usuario" [(ngModel)]="usuario"
                   maxlength="120" placeholder="E-mail ou Celular" required></app-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="recuperarSenha(frmRecuperarSenha);" data-dismiss="modal">Solicitar senha</button>
  </div>
</ng-template>
