import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgSelectConfig } from '@ng-select/ng-select';

const noop = () => {
     // do nothing.
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true
};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  field: AbstractControl;

  @Input() title: string;
  @Input() complemento: string;
  @Input() placeholder = 'Selecione';
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() validationMessage: string;
  @Input() items: any;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() loading: boolean;
  @Input() multiple: boolean;
  @Input() hideSelected: boolean;
  @Input() appendTo: string;
  @Input() clearable = true;
  @Input() addTag = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(
    private injector: Injector,
    private config: NgSelectConfig
  ) {
    this.config.notFoundText = 'Nenhum item encontrado';
    this.config.loadingText = 'Carregando...';
  }

  ngOnInit(): void {
    const model = this.injector.get(NgControl);
    this.field = model.control;
  }

  //The internal data model
  private innerSelectValue: any = '';

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  //get accessor
  get selectValue(): any {
    return this.innerSelectValue;
  }

  //set accessor including call the onchange callback
  set selectValue(value: any) {
    if (value !== this.innerSelectValue) {
      this.innerSelectValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerSelectValue)
      this.innerSelectValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onChange(): void {
    this.change.emit(this.selectValue);
  }
}
