import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CadastroModule } from '../cadastro/cadastro.module';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { MenuComponent } from './menu/menu.component';
import { NoDataAvailableComponent } from './no-data-available/no-data-available.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';


@NgModule({
  declarations: [
    MasterPageComponent,
    BreadCrumbComponent,
    NoDataAvailableComponent,
    HeaderComponent,
    FooterComponent,
    ScrollToTopComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CadastroModule
  ],
  exports: [
    MasterPageComponent,
    NoDataAvailableComponent,
    ScrollToTopComponent
  ]
})
export class LayoutModule { }
