<div *ngIf="!modoEdicao">
  <div class="row mb-3">
    <div class="col-2">
      <small>Itens por página</small>
      <select class="form-select form-select-sm form-select-solid"
              data-control="select2"
              data-placeholder="Select an option"
              [(ngModel)]="itemsPerPage"
              (change)="changeItemsPerPage(itemsPerPage)">
        <option *ngFor="let option of itemsPerPageOptions" [value]="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="col-10" style="display: flex; flex-direction: column; justify-content: flex-end;">
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary mr-2" (click)="novo()"><i class="fas fa-fw fa-plus"></i> Novo {{ titulo }}</button>
        <div class="filter-controls" style="float: right">
          <input class="form-control" [(ngModel)]="filterQuery" (input)="onFilterChange()" placeholder="Filtrar..." />
        </div>
      </div>
    </div>
  </div>
  <app-no-data-available *ngIf="getPageData().length === 0 && !modoEdicao"></app-no-data-available>
</div>

<!-- Table -->
<div *ngIf="!modoEdicao && getPageData().length > 0">
  <table *ngIf="columns.length > 0 && getPageData().length > 0" class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
    <thead>
    <th *ngFor="let column of columns; let idx = index" (click)="sortBy(column)" class="fw-bolder text-muted">
      {{ getColumnAlias(idx) }}
      <span *ngIf="currentSortColumn === column">
        <span *ngIf="sortDirection === 'asc'">▲</span>
        <span *ngIf="sortDirection === 'desc'">▼</span>
      </span>
    </th>
    <th style="width:80px" class="fw-bolder text-muted text-center">Ações</th>
    </thead>
    <tbody>
      <tr *ngFor="let item of getPageData()">
        <td *ngFor="let column of columns" [appHighlight]="filterQuery" [textContent]="item[column]"></td>
        <td class="text-center">
          <a class="text-primary mr-2" (click)="onEditar(item)" title="Editar"><i class="fa fa-edit"></i></a>
          <a class="text-danger" (click)="onExcluir(item)" title="Excluir"><i class="fa fa-trash"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col-md-10">
      <div class="pagination-controls">
        <button [class]="pageItem.value === currentPage
              ? 'btn btn-sm btn-primary hover-scale'
              : pageItem.clickable === false
              ? 'btn btn-sm btn-light-secondary'
              : 'btn btn-sm btn-light-primary hover-scale'"
                *ngFor="let pageItem of generatePageNumbers()"
                (click)="onPageClick(pageItem.value)">
          {{ pageItem.value }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Content -->
<div [hidden]="!modoEdicao">
  <ng-content></ng-content>
  <br /><br />
  <div class="d-flex justify-content-end mb-2">
    <button type="button" class="btn btn-primary mr-2" (click)="onSalvar($event)">Salvar</button>
    <button type="button" class="btn btn-secondary" (click)="onCancelar()">Cancelar</button>
  </div>
</div>
