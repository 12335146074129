import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './controls/card/card.component';
import { CrudComponent } from './controls/crud/crud.component';
import { HighlightDirective } from './controls/crud/highlight.directive';
import { InputModule } from './controls/input/input.module';
import { HomeComponent } from './home/home.component';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';
import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { ModeloEdicaoComponent } from './modelo/modelo-edicao/modelo-edicao.component';
import { ModeloComponent } from './modelo/modelo.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CrudComponent,
    CardComponent,
    HighlightDirective,
    ModeloComponent,
    ModeloEdicaoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LayoutModule,
    InputModule,
    LoginModule,
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 3000
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Nenhum registro encontrado';
    this.config.loadingText = 'Carregando...';
    this.config.typeToSearchText = 'Digite algo para iniciar a pesquisa';
  }
}
