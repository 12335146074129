import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { StorageService } from '../../shared/storage.service';
import { LoginService } from '../login.service';


@Component({
  selector: 'app-login-recuperar-senha',
  templateUrl: './login-recuperar-senha.component.html',
  styleUrls: ['./login-recuperar-senha.component.css']

})
export class LoginRecuperarSenhaComponent extends ComponentBase implements OnInit {
  modal: NgbModalRef;
  urlCallBack: string;

  @Input() usuario: string;
  @Input() primeiroAcesso: boolean;

  @ViewChild('modalRecuperarSenha', { static: true }) modalRecuperarSenha: ElementRef;

  constructor(
    private loginService: LoginService,
    private storage: StorageService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    protected alert: AlertService,
    protected ngbModal: NgbModal,
  ) { super(); }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.urlCallBack = params['urlcallback'];
      });
  }

  abrirModaRecuperarSenha(): void {
    this.modal = this.openModal(this.modalRecuperarSenha, { size: 'md', centered: true });
  }

  recuperarSenha(frmRecuperarSenha?: NgForm) {
    if (frmRecuperarSenha) {
      this.markAsTouched(frmRecuperarSenha);

      if (!frmRecuperarSenha.valid) {
        this.alertarInformacoesInvalidas();
        return;
      }
    }

    this.loading(() => this.loginService.recuperarSenha(this.usuario, btoa(this.urlCallBack)))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.storage.data = response;
        this.modal.close(this.modalRecuperarSenha);
        this.alert.info(`Enviamos uma mensagem para o seu email cadastrado.\nEsta mensagem contém as instruções para que você cadastre uma nova senha de acesso.`);
      });
  }
}
