import { Component } from '@angular/core';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { ItemEntity } from './entities/item.entity';

@Component({
  selector: 'app-modelo',
  templateUrl: './modelo.component.html',
  styleUrls: ['./modelo.component.css']
})
export class ModeloComponent extends ComponentBase {
  data: ItemEntity[] = [
    { codigo: 1, descricao: 'Primeiro' },
    { codigo: 2, descricao: 'Segundo' },
    { codigo: 3, descricao: 'Terceiro' },
    { codigo: 4, descricao: 'Quarto' },
    { codigo: 5, descricao: 'Quinto' },
    { codigo: 6, descricao: 'Sexto' },
    { codigo: 7, descricao: 'Sétimo' }];
  modoEdicao: boolean = false;
  itemSelecionado: ItemEntity = new ItemEntity();

  columns = ['codigo', 'descricao'];
  alias = ['Código', 'Descrição'];

  constructor(
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  novo() {
    this.itemSelecionado = new ItemEntity();
    this.itemSelecionado.novo = true;
    this.modoEdicao = true;
  }

  editar(item: any) {
    Object.assign(this.itemSelecionado, item);
    this.itemSelecionado.novo = false;
    this.modoEdicao = true;
  }

  cancelar(): void {
    this.modoEdicao = false;
  }

  excluir(item: any) {
    this.alert.confirm(`Tem certeza que deseja excluir o item ${item.descricao}?`)
      .then((resp) => {
        if (resp.value) {
          let index = this.data.findIndex(x => x.codigo === item.codigo);
          this.data.splice(index, 1)

          this.data = this.data.slice();

          this.alert.toastr.success('Intem excluído')

          this.cancelar();
        }
      });
  }

  salvar() {
    if (this.itemSelecionado.novo) {
      this.data.push(this.itemSelecionado);

      this.data = this.data.slice();

      this.alert.toastr.success('Intem salvo!')
    }
    else {
      let index = this.data.findIndex(x => x.codigo === this.itemSelecionado.codigo);
      this.data[index] = this.itemSelecionado;

      this.data = this.data.slice();

      this.alert.toastr.success('Intem salvo!')
    }

    this.cancelar();
  }
}
