import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../shared/alert.service';
import { CommonService } from '../../shared/common.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';


@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css']
})
export class MasterPageComponent extends ComponentBase implements OnInit {
  modal: NgbModalRef;
  foto: string;
  nomeUsuario: string;
  identificacaoUsuario: string;

  @ViewChild('modalTempoSessao', { static: true }) modalTempoSessaoRef: ElementRef;

  constructor(
    public session: SessionService,
    protected modalService: NgbModal,
    protected router: Router,
    public common: CommonService,
    protected alert: AlertService,
  ) { super(); }

  ngOnInit(): void {
    this.foto = this.session.googleFoto || '/assets/images/man.png';
    this.nomeUsuario = this.session.usuario.nomePessoa;
    if (this.session.usuario.usuario)
      this.identificacaoUsuario = this.session.usuario.usuario.split('@')[0];
  }

  sair() {
    this.alert.confirm('Tem certeza que deseja sair?')
      .then(val => {
        if (val.value) {
          this.router.navigate(['/']);
        }
      });
  }
}
