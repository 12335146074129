import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EnderecoEntity } from '../cadastro/cadastro-pessoa/entities/endereco-entity';
import { menuConstants } from '../constants/menu.constants';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})

export class CommonService {
  constructor(
    private httpClient: HttpClient,
    private session: SessionService
  ) { }

  obterIp(): Observable<any> {
    return this.httpClient.get<any>('https://get-ip.feev.com.br/get-ip');
  }

  validaIpAcesso(): boolean {
    if (this.session.usuario.codigoCredor > 0 && this.session.usuario.validaIpUsuario && this.session.usuario.validaIpCredor && this.session.usuario.ipsLiberados !== "*") {
      const ips = this.session.usuario.ipsLiberados.split('|');

      return Object.values(ips).indexOf(this.session.usuario.ip) > -1;
    }

    return true;
  }

  mergeObjects(...sources) {
    return sources.reduce((previous, current) => {
      return Object.assign(previous, current)
    });
  }

  isNumber(value: any): boolean {
    return !isNaN(parseInt(value));
  }

  charCodeIsNumber(charCode): boolean {
    return charCode >= 48 && charCode <= 57;
  }

  groupBy(fieldName: string, list: any[]): any[] {
    const _groupBy = key => array =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

    const groupByFieldName = _groupBy(fieldName);

    return groupByFieldName(list);
  }

  getDate(): Date {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  }

  getMonth(): number {
    return this.getDate().getMonth() + 1;
  }

  openFile(blob: Blob, newTab = true): void {
    const file = new Blob([blob], {
      type: blob.type
    });

    const fileURL = URL.createObjectURL(file);

    if (newTab)
      window.open(fileURL, '_blank');
    else
      location.href = fileURL;
  }

  convertBase64ToBlob(dataURI, type = 'image/png') {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type: type });
  }

  openFileFromBase64(dataURI, type = 'image/png') {
    const blob = this.convertBase64ToBlob(dataURI, type);
    this.openFile(blob);
  }

  downloadFile(blob: Blob, fileName: string = null): void {
    const file = new Blob([blob], {
      type: blob.type
    });

    const fileURL = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute('download', fileName);
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));

    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
      link.remove();
    }, 100);
  }

  truncDate(dateTime: Date): Date {
    return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), 0, 0, 0, 0);
  }

  firstDayOfMonth(): Date {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  }

  lastDayOfMonth(): Date {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  subtractDays(date: Date, days: number): Date {
    date.setDate(date.getDate() - days);
    return date;
  }

  getTomorrow(): Date {
    return this.addDays(this.getDate(), 1);
  }

  getYesterday(): Date {
    return this.subtractDays(this.getDate(), 1);
  }

  getFormattedDate(date: Date, format = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
  }

  removerCaracteresEspeciais(valor: string): string {
    if (!valor)
      return '';

    return valor.replace(/[^\w\s]/gi, '').replace(' ', '');
  }

  obterTipoDocumento(cpfCnpj: string): string {
    cpfCnpj = this.removerCaracteresEspeciais(cpfCnpj);
    return cpfCnpj.length > 11 ? "CNPJ" : "CPF";
  }

  formatarCpfCnpj(cpfCnpj: string) {
    if (!cpfCnpj)
      return cpfCnpj;

    const tipoDocumento = this.obterTipoDocumento(cpfCnpj);
    if (tipoDocumento === 'CPF')
      return cpfCnpj.substr(0, 3) + "." + cpfCnpj.substr(3, 3) + "." + cpfCnpj.substr(6, 3) + "-" + cpfCnpj.substr(9, 2);
    else
      return cpfCnpj.substr(0, 2) + "." + cpfCnpj.substr(2, 3) + "." + cpfCnpj.substr(5, 3) + "/" + cpfCnpj.substr(8, 4) + "-" + cpfCnpj.substr(12, 2);
  }

  clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  round(value: number, digits = 2): number {
    return parseFloat(value.toFixed(digits));
  }

  sum(array: any[], field: string): number {
    return array.reduce((sum, current) => sum + current[field], 0);
  }

  min(array: any[], field: string): number {
    return array.reduce((min, p) => p[field] < min ? p[field] : min, array[0][field]);
  }

  obterIdade(dataNascimento: Date): number {
    const hoje = this.getDate();
    let diferencaAnos = hoje.getFullYear() - dataNascimento.getFullYear();
    if (hoje < new Date(hoje.getFullYear(), dataNascimento.getMonth(), dataNascimento.getDate()))
      diferencaAnos--;
    return diferencaAnos;
  }

  formatarTelefone(telefone: string, aplicarMascara = true) {
    if (!telefone)
      return telefone;

    telefone = this.removerCaracteresEspeciais(telefone);
    telefone = this.removerCodigoPaisTelefone(telefone);

    if (telefone.substr(0, 1) === "0")
      telefone = telefone.substr(1);

    if (!aplicarMascara)
      return telefone;

    //Celular com DDD
    if (telefone.length === 11)
      return `(${telefone.substr(0, 2)}) ${telefone.substr(2, 1)}${telefone.substr(3, 4)}-${telefone.substr(7, 4)}`;

    //Fixo com DDD
    if (telefone.length === 10)
      return `(${telefone.substr(0, 2)}) ${telefone.substr(2, 4)}-${telefone.substr(6, 4)}`;

    //Celular sem DDD
    if (telefone.length === 9)
      return `${telefone.substr(0, 1)}${telefone.substr(1, 4)}-${telefone.substr(5, 4)}`;

    //Fixo sem DDD
    if (telefone.length === 8)
      return `${telefone.substr(0, 4)}-${telefone.substr(4, 4)}`;

    return telefone;
  }

  removerCodigoPaisTelefone(telefone: string) {
    telefone = this.removerCaracteresEspeciais(telefone);

    // Remove o codigo do país (Brasil)
    if (telefone.length >= 12 && telefone.substr(0, 2) === "55")
      telefone = telefone.substr(2);

    // Remove o zero antes do DDD
    if (telefone.substr(0, 1) === '0')
      telefone = telefone.substr(1);

    return telefone;
  }

  possuiAcessoRecurso(codigoRecurso: number): boolean {
    return this.session.usuario.recursos.includes(codigoRecurso);
  }

  validarGrupoRecursos(grupo: string): boolean {
    const itens = menuConstants.recursos.filter(recurso => recurso.grupo.descricao === grupo)[0].grupo.itens.map(item => item.codigo);
    return this.session.usuario.recursos.some(recurso => itens.includes(recurso));
  }

  consultarCep(cep: string): Observable<EnderecoEntity> {
    const url = `https://viacep.com.br/ws/${cep.replace('-', '')}/json/?callback=callback_name`;
    return this.httpClient.jsonp<EnderecoEntity>(url, 'callback');
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  encryptNumber(numero: number) {
    let chave = "";
    const guid = this.newGuid().replace(/[-]/g, '');
    const codigo = numero.toString().padStart(20, '0');
    let index = 0;

    for (let i = 1; i <= 10; i++) {
      const parte = codigo.substr(index, 2);
      chave += parte;

      if (i >= 7) {
        const dvparte = parseInt(parte) % (i - 1);
        chave += dvparte;
      }

      if ((i % 2) == 0 && i != 10)
        chave += guid.substr(index, 2);

      index += 2;
    }

    return chave;
  }

  decryptNumber(chave: string) {
    return parseInt(chave.substr(0, 2) + chave.substr(2, 2) + chave.substr(6, 2) + chave.substr(8, 2) + chave.substr(12, 2) + chave.substr(14, 2) + chave.substr(18, 2) + chave.substr(21, 2) + chave.substr(26, 2) + chave.substr(29, 2));
  }

  validarNumeroTelefone(numero: string): boolean {
    numero = numero.replace(/\D/g, '');

    if (numero.length < 8)
      return false;

    // Verificar se não são combinações repetitivas
    if (!/^(\d)\1+$/.test(numero))
      return true;

    return false;
  }

  validarTelefoneCelular(celular: string): boolean {

    celular = this.removerCaracteresEspeciais(celular)

    const regex = new RegExp('^[1-9]{2}(9[1-9])[0-9]{3}[0-9]{4}$');
    return regex.test(celular);
  }

  validarDDD(ddd: string): boolean {
    // List of valid DDDs in Brazil as strings
    const validDDDs: string[] = [
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '21',
      '22', '24', '27', '28', '31', '32', '33', '34', '35', '37',
      '38', '41', '42', '43', '44', '45', '46', '47', '48', '49',
      '51', '53', '54', '55', '61', '62', '63', '64', '65', '66',
      '67', '68', '69', '71', '73', '74', '75', '77', '79', '81',
      '82', '83', '84', '85', '86', '87', '88', '89', '91', '92',
      '93', '94', '95', '96', '97', '98', '99'
    ];

    return validDDDs.includes(ddd);
  }

  validarEmail(email: string): boolean {
    // Expressão regular para validar um endereço de e-mail
    const emailPattern = /^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*[_,\-,\$,\#]?)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$/;

    return emailPattern.test(email);
  }
  obterCodigoSistema(url: string): number {
    if (url.includes('minhaconta'))
      return 2;

    if (url.includes('adm'))
      return 3;

    if (url.includes('credit'))
      return 4;

    if (url.includes('feev'))
      return 5;

    if (url.includes('config'))
      return 6;

    if (url.includes('acordofacil'))
      return 7;

    /*AMBIENTE LOCAL*/
    if (url.includes('localhost:4300'))
      return 2;

    if (url.includes('localhost:4307'))
      return 4;

    if (url.includes('localhost:4301'))
      return 5;

    if (url.includes('localhost:4302'))
      return 6;

    if (url.includes('localhost:4200'))
      return 7;

    return undefined;
  }

 isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}
}
