import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SelectModule } from '../select/select.module';
import { ContasUsuarioComponent } from './contas-usuario.component';


@NgModule({
  declarations: [ContasUsuarioComponent],
  imports: [
    CommonModule,
    FormsModule,
    SelectModule
  ],
  exports: [ContasUsuarioComponent]
})
export class ContasUsuarioModule { }
