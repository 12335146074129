<footer class="footer mt-auto site-footer">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-2">
        <img
          src="/assets/images/logofeev_branco.png"
          alt=""
          class="img-fluid"
          width="150"
        />
      </div>
      <div class="col-md-10">
        <div class="copyright">
          Copyright 2023 - Todos os direitos reservados.
          <div class="version">Versão: 1.0.0</div>
        </div>
      </div>
    </div>
  </div>
</footer>
