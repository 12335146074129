import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AlertService } from "../shared/alert.service";
import { AuthorizationService } from "../shared/authorization.service";
import { SessionService } from "../shared/session.service";


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  ignoreUrls = ['get-ip.feev.com.br'];

  constructor(
    private session: SessionService,
    private alert: AlertService,
    private authorization: AuthorizationService
  ) { }

  private blobError(error: HttpErrorResponse) {
    // https://github.com/angular/angular/issues/19888
    // When request of type Blob, the error is also in Blob instead of object of the json data
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        try {
          const errmsg = (<any>e.target).result;
          reject(new HttpErrorResponse({
            error: errmsg,
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url
          }));

          this.alert.warning(errmsg);
        } catch (e) {
          reject(error);
          this.alert.warning(error.message);
        }
      };

      reader.onerror = (e) => {
        reject(error);
      };

      reader.readAsText(error.error);
    });
  }

  private handleError(req: HttpRequest<any>, error: HttpErrorResponse) {
    if (error.status) {
      if (error.status === 400)
        if (typeof error.error === "string")
          this.alert.warning(error.error);
        else if (error.error instanceof Blob)
          this.blobError(error);
        else {
          if (error.message)
            this.alert.warning(error.message);
          else
            this.alert.warning(JSON.stringify(error.error));
        }
      else
        this.alert.error(`Url: ${req.url}`, error.status);
    }
    else
      this.alert.error(`Url: ${req.url}`);

    return throwError(error);
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    return this.authorization.renovarToken()
      .pipe(
        switchMap(() => {
          req = this.addAuthHeader(req);
          return next.handle(req);
        })
      );
  }

  private addAuthHeader(request: HttpRequest<any>) {
    for (let x = 0; x < this.ignoreUrls.length; x++) {
      if (request.url.indexOf(this.ignoreUrls[x]) > -1)
        return request;
    }

    if (this.authorization.token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', `Bearer ${this.authorization.token}`);

      if (this.session.usuario.codigoCredor)
        headers = headers.append('CodigoCredor', this.session.usuario.codigoCredor.toString());

      if (this.session.usuario.grupoCredores)
        headers = headers.append('GrupoEmissor', this.session.usuario.grupoCredores);

      return request.clone({
        headers: headers
      });
    }

    return request;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthHeader(req);

    return next.handle(req).pipe(
      catchError(
        error => {
          if (error.status && error.status === 401)
            return this.handle401Error(req, next, error);

          return this.handleError(req, error);
        })
    );
  }
}
