import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {

  @Input('appHighlight') searchTerm: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    const text = this.el.nativeElement.textContent;

    if (!this.searchTerm || !this.searchTerm.length || !text) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', text);
      return;
    }

    const pattern = new RegExp(`(${this.searchTerm})`, 'gi');
    const highlightedText = text.replace(pattern, `<mark>$1</mark>`);
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', highlightedText);
  }

}
