import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { take } from 'rxjs/operators';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { EncryptService } from '../../shared/encrypt.service';
import { AlterarSenhaMultiplasContasRequestEntity } from '../entities/alterar-senha-multiplas-contas-request.entity';
import { ConsultarUsuariosCadastroSenhaResponseEntity } from '../entities/responses/consultar-usuarios-cadastro-senha-response.entity';
import { LoginService } from '../login.service';


@Component({
  selector: 'app-login-alterar-senha',
  templateUrl: './login-alterar-senha.component.html',
  styleUrls: ['./login-alterar-senha.component.css']
})
export class LoginAlterarSenhaComponent extends ComponentBase implements OnInit {
  senha: string;
  confSenha: string;
  usuario: string;
  urlCallBack: string;
  primeiroAcesso: boolean;
  codigosUsuarios: number[];
  usuarios: ConsultarUsuariosCadastroSenhaResponseEntity[] = [];
  userEncrypt: string;

  @Output() onSenhaAlterada: EventEmitter<any> = new EventEmitter();

  constructor(
    private loginService: LoginService,
    private encryptService: EncryptService,
    private activatedRoute: ActivatedRoute,
    protected alert: AlertService,
    protected router: Router
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();
    this.primeiroAcesso = false;

    this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.usuario = params['usuario'];
        const user = this.encryptService.decryptData(this.usuario);

        if (params['firstaccess']) {
          this.primeiroAcesso = true;
        }
        this.urlCallBack = params['urlcallback']

        if (this.urlCallBack)
          this.urlCallBack = atob(this.urlCallBack);

        this.loading(() => this.loginService.consultarUsuariosCadastroSenha(btoa(user)))
          .pipe(take(1))
          .subscribe((usuarios: ConsultarUsuariosCadastroSenhaResponseEntity[]) => {
            if (usuarios.length === 0) {
              this.alert.warning('Usuário não encontrado.');
              return;
            }

            this.userEncrypt = this.encryptService.encryptData(usuarios[0].usuario);

            if (this.primeiroAcesso) {
              this.usuarios = usuarios.filter(x => x.senhaCadastrada === false);
            }
            else
              this.usuarios = usuarios;

            if (this.usuarios.length === 1) {
              this.codigosUsuarios = []
              this.codigosUsuarios.push(this.usuarios[0].codigoUsuario);
            }
          })
      });
  }

  onkeyUp(event: any): void {
    if (event.keyCode === 13)
      this.alterarSenha();
  }

  alterarSenha() {
    const user = this.encryptService.decryptData(this.usuario);

    if (!this.codigosUsuarios || this.codigosUsuarios.length === 0) {
      this.alert.warning('Selecione ao menos uma conta para alterar a senha.');
      return;
    }

    if (user === this.senha.toUpperCase()) {
      this.alert.warning('Sua senha não pode ser igual ao seu usuário');
      return;
    }

    if (this.senha.length < 6) {
      this.alert.warning('A senha precisa ter ao menos 6 dígitos.');
      return;
    }

    if (this.senha !== this.confSenha) {
      this.alert.warning('As senhas não são iguais! É preciso informar a mesma senha nos dois campos');
      return;
    }

    const request = new AlterarSenhaMultiplasContasRequestEntity();
    request.confirmacaoSenha = this.confSenha;
    request.senha = this.senha;

    this.codigosUsuarios.forEach(codigo => {
      const codigoUsuario = this.encryptService.encryptData(codigo.toString());
      request.usuariosEncrypt.push(codigoUsuario);
    });

    this.loading(() => this.loginService.alterarSenhaMultiplasContas(request))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.info("Sua senha foi alterada com sucesso.")
          .then(() => {
            const passEncrypt = this.encryptService.encryptData(this.senha);
            this.onSenhaAlterada.emit({ 'user': this.userEncrypt, 'pass': passEncrypt, 'urlCallback': this.urlCallBack });
          });
      });
  }
}
