import { Injectable } from '@angular/core';
import { Validator, ValidationErrors, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DateValidatorService implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    let isValid = true;

    if (control.value && typeof (control.value) === 'string') {
      const date = new Date(control.value);
      isValid = !isNaN(date.valueOf());
    }
    else if (control.value instanceof Date)
      isValid = !isNaN(control.value.valueOf());

    return isValid ? null : { validDate: false };
  }
}
