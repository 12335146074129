import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Directive, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";

import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { Observable, Subject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AlertService } from "./alert.service";
import { Dictionary } from "./dictionary";
import { appInjector } from "./injector";

declare let $: any;

@Directive()

export abstract class ComponentBase implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(DataTableDirective)
  dataTables: QueryList<DataTableDirective>;

  unsubscribe$: Subject<void> = new Subject<void>();
  dtTriggers: Dictionary<Subject<void>> = {};
  dtOptions: DataTables.Settings = {};
  loadingTela = true;
  reloadBySearch = false;

  constructor(
    protected http?: HttpClient,
    protected alert?: AlertService,
    protected router?: Router,
    protected ngbModal?: NgbModal
  ) {
    const _$ = this.ngOnDestroy;
    this.ngOnDestroy = () => {
      _$();
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }

    if (appInjector())
      this.http = appInjector().get(HttpClient);
  }

  ngOnInit(): void {
    // Carregamento MaterialPro
    setTimeout(() => {
      $("#main-wrapper").AdminSettings({
        Theme: false, // this can be true or false ( true means dark and false means light ),
        Layout: 'horizontal',
        LogoBg: 'skin4', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        NavbarBg: 'skin4', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        SidebarType: 'full', // You can change it full / mini-sidebar / iconbar / overlay
        SidebarColor: 'skin6', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        SidebarPosition: true, // it can be true / false ( true means Fixed and false means absolute )
        HeaderPosition: true, // it can be true / false ( true means Fixed and false means absolute )
        BoxedLayout: true, // it can be true / false ( true means Boxed and false means Fluid )
      });

      this.sidebarmenuMaterialPro();
      this.customMaterialPro();
    }, 100);

    if ($("table[triggered]").length > 0) {
      for (let i = 0; i < $("table[triggered]").length; i++) {
        if ($("table[triggered]")[i].id)
          this.dtTriggers[$("table[triggered]")[i].id] = new Subject<void>();
      }
    }

    this.dtOptions = {
      searching: true,
      ordering: false,
      order: [],
      columnDefs: [{ targets: 'no-sort', 'orderable': false }],
      pagingType: 'full_numbers',
      pageLength: 50,
      lengthChange: true,
      language: {
        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        search: "Pesquisar",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      },
      drawCallback(settings: any): void {
        if (this.api().rows().count() === 0)
          $(this.api().context[0].nTableWrapper).find('.dataTables_empty').closest('tr').remove();
      }
    };

    if (this.router)
      this.router.events
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((event: NavigationEnd) => {
          window.scroll(0, 0);
        });
  }

  ngAfterViewInit(): void {
    if (this.dataTables) {
      this.dataTables.forEach(table => {
        if (table['el'].nativeElement.attributes['triggered'])
          this.dtTriggers[table['el'].nativeElement.id].next();
      });

      //Desliga a ação padrão de pesquisa do grid e implementa uma própria com controle de debounce.
      //Esta funcionalidade somente é aplicada para tables com o ajax configurado
      //setTimeout(() => {
      //  $('.dataTables_filter input').off('keyup');
      //  let searchDelay = null;
      //  $('.dataTables_filter input').on('keyup', function () {
      //    clearTimeout(searchDelay);
      //    const input = $(this);
      //    const search = input.val() as string;
      //    searchDelay = setTimeout(function () {
      //      const dtTable = $("#" + input.attr('aria-controls')).dataTable().api();
      //      dtTable.search(search).draw();
      //    }, 300);
      //  });
      //}, 100);
    }
  }

  protected refreshDataTable(id: string) {
    if (this.dataTables) {
      this.dataTables.forEach(table => {
        if (table['el'].nativeElement.id === id) {
          table.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTriggers[table['el'].nativeElement.id].next();
          });
        }
      });
    }
  }

  //protected reloadDataTable(id: string) {
  //  if (this.dataTables) {
  //      this.dataTables.forEach(table => {
  //          if (table['el'].nativeElement.id === id) {
  //              table.dtInstance.then((dtInstance: DataTables.Api) => {
  //                  dtInstance.ajax.reload();
  //              });
  //          }
  //      });
  //  }
  //}

  protected clearDataTable(id: string) {
    if (this.dataTables) {
      this.dataTables.forEach(table => {
        if (table['el'].nativeElement.id === id) {
          table.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            dtInstance.draw();
          });
        }
      });
    }
  }

  protected informarNenhumRegistroEncontrado(): void {
    if (this.alert)
      this.alert.info('Nenhum registro encontrado.');
    else
      throw new Error('AlertService não injetado em ComponentBase');
  }

  protected alertarInformacoesInvalidas(): void {
    if (this.alert)
      this.alert.warning('Favor verificar informações inválidas na tela. Os campos estão indicados em vermelho.');
    else
      throw new Error('AlertService não injetado em ComponentBase');
  }

  protected call<T>(fn: Function): Observable<T> {
    return fn() as Observable<T>;
  }

  protected loading<T>(fn: Function, button?: HTMLButtonElement): Observable<T> {
    $("#lodingPanel").addClass('carregando');

    return fn()
      .pipe(finalize(() => {
        $("#lodingPanel").removeClass('carregando');
      })) as Observable<T>;
  }

  protected showLoading(button?: HTMLButtonElement): void {
    $("#lodingPanel").addClass('carregando');
  }

  protected hideLoading(button?: HTMLButtonElement): void {
    $("#lodingPanel").removeClass('carregando');
  }

  protected markAsTouched(form: NgForm): void {
    form.form.markAllAsTouched();
  }

  protected resetForm(form: NgForm): void {
    form.form.markAsPristine();
    form.form.markAsUntouched();
    form.form.updateValueAndValidity();
  }

  protected openModal(content: any, options?: NgbModalOptions): NgbModalRef {
    const defaultOptions: NgbModalOptions = { size: 'lg', backdrop: 'static' };
    if (options)
      Object.assign(defaultOptions, options);

    return this.ngbModal.open(content, defaultOptions);
  }

  customMaterialPro(): void {
    $(".preloader").fadeOut();

    $(".left-sidebar").hover(
      function () {
        $(".navbar-header").addClass("expand-logo");
      },
      function () {
        $(".navbar-header").removeClass("expand-logo");
      }
    );
    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").on('click', function () {
      $("#main-wrapper").toggleClass("show-sidebar");
      $(".nav-toggler i").toggleClass("ti-menu");
    });
    $(".nav-lock").on('click', function () {
      $("body").toggleClass("lock-nav");
      $(".nav-lock i").toggleClass("mdi-toggle-switch-off");
      $("body, .page-wrapper").trigger("resize");
    });
    $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
      $(".app-search").toggle(200);
      $(".app-search input").focus();
    });

    // ==============================================================
    // Right sidebar options
    // ==============================================================
    $(function () {
      $(".service-panel-toggle").on('click', function () {
        $(".customizer").toggleClass('show-service-panel');

      });
      $('.page-wrapper').on('click', function () {
        $(".customizer").removeClass('show-service-panel');
      });
    });
    // ==============================================================
    // This is for the floating labels
    // ==============================================================
    $('.floating-labels .form-control').on('focus blur', function (e) {
      $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    // ==============================================================
    //tooltip
    // ==============================================================
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    // ==============================================================
    //Popover
    // ==============================================================
    $(function () {
      $('[data-toggle="popover"]').popover()
    })

    // ==============================================================
    // Perfact scrollbar
    // ==============================================================
    $('.message-center, .customizer-body, .scrollable').perfectScrollbar({
      wheelPropagation: !0
    });

    /*var ps = new PerfectScrollbar('.message-body');
    var ps = new PerfectScrollbar('.notifications');
    var ps = new PerfectScrollbar('.scroll-sidebar');
    var ps = new PerfectScrollbar('.customizer-body');*/

    // ==============================================================
    // Resize all elements
    // ==============================================================
    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper").delay(20).show();

    // ==============================================================
    // To do list
    // ==============================================================
    $(".list-task li label").click(function () {
      $(this).toggleClass("task-done");
    });

    // ==============================================================
    // Collapsable cards
    // ==============================================================
    $('a[data-action="collapse"]').on('click', function (e) {
      e.preventDefault();
      $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
      $(this).closest('.card').children('.card-body').collapse('toggle');
    });
    // Toggle fullscreen
    $('a[data-action="expand"]').on('click', function (e) {
      e.preventDefault();
      $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
      $(this).closest('.card').toggleClass('card-fullscreen');
    });
    // Close Card
    $('a[data-action="close"]').on('click', function () {
      $(this).closest('.card').removeClass().slideUp('fast');
    });
    // ==============================================================
    // LThis is for mega menu
    // ==============================================================
    $(document).on('click', '.mega-dropdown', function (e) {
      e.stopPropagation()
    });
    // ==============================================================
    // This is for the innerleft sidebar
    // ==============================================================
    $(".show-left-part").on('click', function () {
      $('.left-part').toggleClass('show-panel');
      $('.show-left-part').toggleClass('ti-menu');
    });
  }

  sidebarmenuMaterialPro(): void {
    const url = window.location + "";
    const path = url.replace(window.location.protocol + "//" + window.location.host + "/", "");
    const element = $('ul#sidebarnav a').filter(function () {
      return this.href === url || this.href === path;// || url.href.indexOf(this.href) === 0;
    });
    element.parentsUntil(".sidebar-nav").each(function (index) {
      if ($(this).is("li") && $(this).children("a").length !== 0) {
        //$(this).children("a").addClass("active");
        //$(this).parent("ul#sidebarnav").length === 0
        //  ? $(this).addClass("active")
        //  : $(this).addClass("selected");
      }
      else if (!$(this).is("ul") && $(this).children("a").length === 0) {
        // $(this).addClass("selected");
      }
      else if ($(this).is("ul")) {
        $(this).addClass('in');
      }
    });

    element.addClass("active");
    $('#sidebarnav a').on('click', function (e) {

      if (!$(this).hasClass("active")) {
        // hide any open menus and remove all other classes
        $("ul", $(this).parents("ul:first")).removeClass("in");
        $("a", $(this).parents("ul:first")).removeClass("active");

        // open our new menu and add the open class
        $(this).next("ul").addClass("in");
        $(this).addClass("active");

      }
      else if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(this).parents("ul:first").removeClass("active");
        $(this).next("ul").removeClass("in");
      }
    })
    $('#sidebarnav >li >a.has-arrow').on('click', function (e) {
      e.preventDefault();
    });
  }


  possuiAcessoRecurso(codigoRecurso: number): boolean {
    const usuario = JSON.parse(sessionStorage.getItem('usuario'));
    if (usuario === null)
      return false;

    return usuario.recursos.indexOf(codigoRecurso) > -1;
  }

  forceRoute(uri: string) {
    this.router.navigateByUrl('/atendimento', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  log(title: string, obj: any) {
    this.http.post(`${environment.apiSeguranca}log/incluirLog?titulo=${title}&groupName=feev`, obj)
      .pipe(take(1))
      .subscribe(() => {
        console.log(title, obj);
      });
  }

  ngOnDestroy(): void {
    // do nothing.
  }
}
