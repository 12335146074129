<ng-template #modalContasUsuario let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Selecione a conta de acesso</h5>
    <button type="button" class="close" aria-label="Close" (click)="c()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-select name="codigoUsuario" [(ngModel)]="codigoUsuario" title="contas" [items]="contasUsuario" bindValue="codigoUsuario" bindLabel="nomeCredor" [clearable]="false" required></app-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="selecionarConta()">Selecionar conta</button>
    <button type="button" class="btn btn-secondary" (click)="c()" ngbAutofocus>Fechar</button>
  </div>
</ng-template>
