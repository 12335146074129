export class UsuarioEntity {
  codigoUsuario: number;
  usuario: string;
  nomePessoa: string;
  senha: string;
  googleUid: string;
  hasHash: boolean;
  codigoCredor: number;
  nomeCredor: string;
  grupoCredores: string;
  ip: string;
  recursos: number[] = [];
  tempoSessao: number;
  administrador: boolean;
  validaIpUsuario: boolean;
  validaIpCredor: boolean;
  ipsLiberados: string;
  celular: string;
  email: string;
  celularValidado: boolean;
  emailValidado: boolean;
  alterarSenha: boolean;
  codigoSistema: number;
  
  constructor(init?: Partial<UsuarioEntity>) {
    Object.assign(this, init);
  }
}
