import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { DateValidatorService } from '../../validators/date-validator.service';
import { MaskService } from './mask.service';

const noop = () => {
   // do nothing.
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR,
    { provide: NG_VALIDATORS, useExisting: InputComponent, multi: true }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit, Validator {
  field: AbstractControl;
  showPassword: boolean = false;

  @Input() title: string;
  @Input() complemento: string;
  @Input() maxlength: number;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() validationMessage: string;
  @Input() type = 'text';
  @Input() mask: string;
  @Input() rows = 5;
  @Input() datepicker: boolean;
  @Input() class: string;
  @Output() blur: EventEmitter<void> = new EventEmitter();
  @ViewChild("appInputRef") inputRef: ElementRef;

  constructor(
    private injector: Injector,
    private maskService: MaskService,
    private dateValidator: DateValidatorService
  ) { }

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.field = model.control;

    this.datepicker = this.datepicker != undefined;
    if (this.datepicker) {
      this.mask = '00/00/0000';
      if (!this.validationMessage)
        this.validationMessage = 'Informe uma data válida.';
    }
  }

  ngAfterViewInit() {
    let inputRef = this.inputRef;
    if (this.datepicker)
      inputRef = (<any>this.inputRef)._elRef;
    this.maskService.init(this.mask, inputRef);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  //The internal data model
  private innerValue: any = '';

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  // Evento acionado quando ocorre modificação no DOM.
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = this.maskService.transform(this.mask, v, this.innerValue, this.inputRef);
      this.onChangeCallback(this.maskService.parser(this.mask, this.innerValue, this.inputRef));
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
    this.blur.emit();
  }

  //From ControlValueAccessor interface
  // Evento acionado quando ocorre modificação na model
  writeValue(value: any) {
    if (value !== this.innerValue) {
      value = this.maskService.formatter(this.mask, value, this.inputRef);
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  // Evento acionado quando ocorre modificação no DOM.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.datepicker)
      return this.dateValidator.validate(control);
    return null;
  }
}

