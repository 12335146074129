export const menuConstants = {
  recursos: [
    {
      grupo: {
        descricao: 'Início',
        itens: [

        ]
      }
    },
    {
      grupo: {
        descricao: 'Cadastro',
        itens: [
          { descricao: 'Cadastro', codigo: 582, subGrupo: null, rota: '/home' }
        ]
      }
    }
  ]
};
