import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceBase } from '../../shared/service-base';
import { AlterarSenhaRequestEntity } from './entities/request/alterar-senha-request-entity';

@Injectable({
  providedIn: 'root'
})
export class CadastroUsuarioService extends ServiceBase {
  constructor(
    private http: HttpClient
  ) { super(); }

  alterarSenha(entity: AlterarSenhaRequestEntity): Observable<void> {
    return this.http.post<void>(`${environment.apiSeguranca}Autenticacao/AlterarSenha/`, entity);
  }
}
