<div class="form-group">
  <!--Input simples-->
  <label class="form-control-label" *ngIf="title">{{ title }} <span class="text-danger" *ngIf="required || required === ''">*</span></label>
  <div class="input-group">
    <input [type]="type"
           class="form-control form-control-danger"
           [ngClass]="{'is-invalid': field.invalid && field.touched, 'class': class}"
           [attr.maxlength]="maxlength"
           [attr.placeholder]="placeholder"
           [(ngModel)]="value"
           (blur)="onBlur()"
           [required]="required"
           [disabled]="disabled"
           [readonly]="readonly"
           #appInputRef
           *ngIf="type !== 'textarea' && type !== 'password' && !datepicker" />
    <div class="input-group-append">
      <ng-content></ng-content>
    </div>
  </div>
  <!--textarea-->
  <textarea class="form-control form-control-danger"
            [ngClass]="{'is-invalid': field.invalid && field.touched, 'class': class}"
            [rows]="rows"
            [attr.maxlength]="maxlength"
            [attr.placeholder]="placeholder"
            [(ngModel)]="value"
            (blur)="onBlur()"
            [required]="required"
            [disabled]="disabled"
            [readonly]="readonly"
            #appInputRef
            *ngIf="type === 'textarea'"></textarea>
  <!--Input com calendário-->
  <div class="input-group" *ngIf="datepicker">
    <input [type]="type"
           class="form-control form-control-danger form-input-calendar"
           [ngClass]="{'is-invalid': field.invalid && field.touched, 'class': class}"
           [attr.maxlength]="maxlength"
           [attr.placeholder]="placeholder"
           [(ngModel)]="value"
           (blur)="onBlur()"
           [required]="required"
           [disabled]="disabled"
           [readonly]="readonly"
           ngbDatepicker
           firstDayOfWeek="7"
           #appInputRef="ngbDatepicker"
           container="body"
           (dateSelect)="onBlur()" />
    <button class="btn btn-outline-secondary fa fa-calendar" (click)="appInputRef.toggle()" type="button" [ngClass]="{'btn-outline-danger': field.invalid && field.touched}"></button>
  </div>
  <!--Input senha-->
  <div *ngIf="type === 'password'" class="input-group">
    <input [type]="showPassword ? 'text' : 'password'"
           class="form-control form-control-danger"
           [ngClass]="{'is-invalid': field.invalid && field.touched, 'class': class}"
           [attr.maxlength]="maxlength"
           [attr.placeholder]="placeholder"
           [(ngModel)]="value"
           (blur)="onBlur()"
           [required]="required"
           [disabled]="disabled"
           [readonly]="readonly"
           #appInputRef />

    <span class="input-group-text togg-password-visibility">
      <i class="fa" [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"
         (click)="togglePasswordVisibility()"
         style="cursor: pointer"></i>
    </span>
  </div>
  <!--Complemento-->
  <small class="form-text text-primary complemento" *ngIf="complemento">{{ complemento }}</small>
  <!--Mensagem de validação-->
  <div class="text-danger" *ngIf="validationMessage && field.invalid && field.touched">
    {{ validationMessage }}
  </div>
</div>
