<app-card titulo="Lorem Ipsum" descricao="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua">
  <div class="card-body py-3">
    <app-crud [columns]="columns"
               [alias]="alias"
               [data]="data"
               [titulo]="'modelo'"
               (clickBotaoNovo)="novo()"
               [modoEdicao]="modoEdicao"
               (editar)="editar($event)"
               (excluir)="excluir($event)"
               (salvar)="salvar()"
               (cancelar)="cancelar()">
      <app-modelo-edicao [item]="itemSelecionado"></app-modelo-edicao>
    </app-crud>
  </div>
</app-card>
