<div class="row m-0 p-0" [hidden]="urlInvalida">
  <span class="rodape" *ngIf="this.codigoSistema === 5">{{getYear()}} | Feev Tecnologia</span>
  <span class="rodape rodape-2safe" *ngIf="this.codigoSistema !== 2 && this.codigoSistema !== 7">
    <span>2Safe Tecnologia Ltda - 05.739.862/0001-54</span>
    <span>2020 | Todos os direitos reservados.®</span>
  </span>
  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 m-0 p-0" id="page-login">

    <div class="logo_2safe_branca" *ngIf="this.codigoSistema === 2">
      <img src="../../assets/images/2safe_branco.png" alt="" />
    </div>
    <div class="digital" *ngIf="this.codigoSistema === 2">
      <img src="https://2safe-site.s3.amazonaws.com/digital.png" alt="" />
    </div>

    <!-- <div class="logo_2safe_branca" *ngIf="this.codigoSistema === 7">
      <img src="../../assets/images/acordo-facil-logo-white.png" alt="" />
    </div>
    <div class="atendente" *ngIf="this.codigoSistema === 7">
      <img src="../../assets/images/acordo-atendente.png" alt="" />
    </div> -->

    <div class="landing-acordo-facil" *ngIf="this.codigoSistema === 7">
      <div class="acordo-logo">
        <img src="../../assets/images/acordo-facil-logo-branca.png" alt="" />
      </div>
      <div class="atendente">
        <img src="../../assets/images/acordo-atendente.png" alt="" />
      </div>
    </div>

    <img src="../../assets/images/background/grafismo.png" alt="" class="logo" *ngIf="this.codigoSistema === 5 || this.codigoSistema === 6" />
    <img src="../../assets/images/logofeev_color2.png" alt="" class="logo2" *ngIf="this.codigoSistema === 5 || this.codigoSistema === 6" />

  </div>

  <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 m-0 p-0 login">
    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="!codigoSistema">
      <strong>Erro!</strong> O código do sistema não foi informado corretamente.
    </div>
    <form #frmLogin="ngForm" [hidden]="!codigoSistema">
      <div *ngIf="!alterarSenha" id="card-login" class="d-flex align-items-center justify-content-center shadow-sm">
        <div>
          <div *ngIf="!alterarSenha && !validacaoDados" class="entrar-container">
            <div class="text-center">
              <!--Logo-->
              <h1 class="titulo">Entrar {{getPlataforma()}}</h1>
            </div>
            <!--Login-->
            <div class="email-senha-container">
              <div class="text-start input-grupo">
                <label for="usuario" class="subtitulo">E-mail ou celular</label>
                <!-- <input name="usuario" id="usuario" class="caixa-texto" type="text" (keyup)="onkeyUp($event)"
          [(ngModel)]="identificacaoUsuario" title="E-mail ou Celular" /> -->
                <app-input class="caixa-texto" [(ngModel)]="identificacaoUsuario" name="usuario" id="usuario" type="text" (keyup)="onkeyUp($event)"></app-input>
              </div>
              <div class="text-start input-grupo">
                <label for="senha" class="subtitulo">Senha</label>
                <!-- <input name="senha" id="senha" class="caixa-texto" type="password" (keyup)="onkeyUp($event)"
          [(ngModel)]="senhaAcesso" /> -->
                <app-input class="caixa-texto" [(ngModel)]="senhaAcesso" name="senha" id="senha" type="password" (keyup)="onkeyUp($event)"></app-input>
              </div>
              <div class="d-flex justify-content-between w-100">
                <a class="text-primary esqueceu-senha" href="javascript:;" (click)="recuperarSenha(false)">
                  Esqueceu a
                  senha?
                </a>
                <!--<div class="d-flex">
          <a class="text-primary esqueceu-senha" href="javascript:;" (click)="recuperarSenha(true)">Primeiro acesso?</a>
        </div>-->
              </div>
            </div>
            <div class="login-buttons-container">
              <div class="text-center">
                <button class="btn btn-primary btn-md btn-block" type="button" (click)="acessar()" #btn>
                  Entrar
                </button>
              </div>
              <div class="text-center">
                <button type="button" class="btn-google" title="Acessar com o Google" (click)="autenticarGoogle()">
                  <img src="/assets/images/icon/google.svg" alt="Google" />
                  <span>Entrar com o Google</span>
                </button>
              </div>
            </div>

          </div>
          <!-- Validação de e-mail de celular -->
          <div *ngIf="validacaoDados">
            <div class="text-center mt-1 mb-4">
              <!-- Logo -->
              <h1 class="titulo">Validação de acesso</h1>
              <h6 class="subtitulo">
                Informe o código enviado ao seu celular e e-mail
              </h6>
            </div>
            <div class="d-flex flex-column align-items-center">
              <div *ngIf ="celular" class="col-lg-7 text-center">
                <label for="codigo-celular" class="subtitulo">Código enviado ao celular</label><br />
                <app-input name="codigo-celular" id="codigo-celular" class="caixa-texto w-100" type="text" maxlength="4" [(ngModel)]="codigoCelular"></app-input>
            </div>
            <div *ngIf="email" class="col-lg-7 text-center">
              <label for="codigo-email" class="subtitulo">Código enviado ao e-mail</label><br />
              <app-input name="codigo-email" id="codigo-email" class="caixa-texto w-100" type="text" maxlength="4" [(ngModel)]="codigoEmail"></app-input>
            </div>
          </div>
          <div class="my-3 text-center">
            <button class="btn btn-primary btn-md btn-block text-uppercase" type="button"
                    (click)="validarCodigoEmailCelular()">
              Validar Código
            </button>
          </div>
          <div class="col-12 text-center mt-0">
            <a class="text-primary" href="javascript:;" (click)="reenviarCodigo()">Reenviar Código</a>
          </div>
        </div>
        </div>
      </div>
      <!--Alteração de senha-->
      <app-login-alterar-senha (onSenhaAlterada)="continuarLogin($event)"
        *ngIf="alterarSenha"></app-login-alterar-senha>
    </form>
  </div>
</div>

<app-login-recuperar-senha [usuario]="identificacaoUsuario"></app-login-recuperar-senha>
<app-contas-usuario (contaSelecionada)="contaUsuarioSelecionada($event)"></app-contas-usuario>

<!--Modal Selecionar Grupo de Credor e Credor-->
<ng-template #modalGrupoCredor let-c="close" let-d="dismiss">
  <div class="modal-header background-secondary">
    <h4 class="modal-title text-white">Selecione uma Conta</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="c(); cancelarAlteracoes()">
    </button>
  </div>
  <div class="modal-body">
    <table *ngIf="contasUsuario.length > 1">
      <tr>
        <td style="width: 100%;">
          <app-select name="empresa" [(ngModel)]="hash" title="Conta de Usuário"
            title="Selecione uma conta de usuário para continuar." [disabled]="contasUsuario.length === 1"
            [items]="contasUsuario" bindValue="hash" bindLabel="descricao"
            (change)="selecionarContaUsuario()"></app-select>
        </td>
        <td style="padding-left: 7px; padding-top: 20px; font-size: 20px; cursor: pointer;" (click)="showInfo()">
          <i class="fa fa-info-circle"
            title="Foram encontradas mais de uma conta de usuário para o e-mail/celular informado. Selecione uma conta para continuar."></i>
        </td>
      </tr>
    </table>
    <app-select name="empresa" [(ngModel)]="codigoGrupo" title="Grupo de Credores"
      [disabled]="grupoCredores.length === 1" [items]="grupoCredores" bindValue="codigoGrupoCredor"
      *ngIf="showGrupoCredor" bindLabel="nomeGrupoCredor" (change)="carregarCredores($event)"></app-select>

    <app-select name="credor" [(ngModel)]="codigoCredor" title="Credores" [disabled]="credores.length === 1" *ngIf="showGrupoCredor"
      [items]="credores" bindValue="codigoCredor" bindLabel="nomeCredor"
      (change)="selecionarCredor($event)"></app-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="c(); cancelarAlteracoes()">
      Fechar
    </button>
    <button class="btn btn-primary" (click)="selecionarConta()" [disabled]="(!codigoGrupo || !codigoCredor ) && !ignoraGrupoCredor">
      Selecionar conta
    </button>
  </div>
</ng-template>
