<div class="d-flex flex-column h-100">
  <app-header></app-header>
  <main id="main" class="flex-shrink-0">
    <div class="container mt-4">
      <app-bread-crumb></app-bread-crumb>
      <ng-content></ng-content>
    </div>
  </main>
  <app-footer></app-footer>
  <app-scroll-to-top></app-scroll-to-top>
</div>
