import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { take } from 'rxjs/operators';
import { AlertService } from '../../../shared/alert.service';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';
import { CadastroUsuarioService } from '../cadastro-usuario.service';
import { AlterarSenhaRequestEntity } from '../entities/request/alterar-senha-request-entity';


@Component({
  selector: 'app-cadastro-usuario-alterar-senha',
  templateUrl: './cadastro-usuario-alterar-senha.component.html',
  styleUrls: ['./cadastro-usuario-alterar-senha.component.css']
})
export class CadastroUsuarioAlterarSenhaComponent extends ComponentBase {
  modal: NgbModalRef;
  alterarSenhaRequest: AlterarSenhaRequestEntity = new AlterarSenhaRequestEntity;

  @ViewChild('modalAlterarSenha', { static: true }) modalAlterarSenhaRef: ElementRef;

  constructor(
    protected session: SessionService,
    private usuarioService: CadastroUsuarioService,
    protected alert: AlertService,
    protected modalService: NgbModal
  ) { super(); }


  abrirModalAlterarSenha(): void {
    this.modal = this.modalService.open(this.modalAlterarSenhaRef, { size: 'sm' });
  }

  onkeyUp(event: any): void {
    if (event.keyCode === 13)
      this.alterarSenha();
  }

  alterarSenha(frmAlterarSenha?: NgForm) {
    if (frmAlterarSenha) {
      this.markAsTouched(frmAlterarSenha);

      if (!frmAlterarSenha.valid) {
        this.alertarInformacoesInvalidas();
        return;
      }
    }

    if (!this.session.usuario) {
      this.alert.warning('Não foi possível recuperar os dados da sessão atual.')
        .then((r) => {
          window.location.href = "/";
        });
    }

    if (!this.alterarSenhaRequest.senha || this.alterarSenhaRequest.confirmacaoSenha.length < 6) {
      this.alert.warning('A senha precisa ter ao menos 6 dígitos.');
      return;
    }

    if (this.alterarSenhaRequest.senha !== this.alterarSenhaRequest.confirmacaoSenha) {
      this.alert.warning('Sua senha e a confirmação de senha não conferem.');
      return;
    }

    this.alterarSenhaRequest.usuario = btoa(this.session.usuario.codigoUsuario.toString());
    this.loading(() => this.usuarioService.alterarSenha(this.alterarSenhaRequest))
      .pipe(take(1))
      .subscribe((response: any) => {
        this.alert.info("Sua senha foi alterada com sucesso.");
        this.modal.close(this.modalAlterarSenhaRef);
        this.alterarSenhaRequest = new AlterarSenhaRequestEntity();
      });
  }
}
