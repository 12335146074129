import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioEntity } from '../../login/entities/usuario.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';


@Component({
  selector: 'app-contas-usuario',
  templateUrl: './contas-usuario.component.html',
  styleUrls: ['./contas-usuario.component.css']
})

export class ContasUsuarioComponent extends ComponentBase implements OnInit {
  contasUsuario: UsuarioEntity[] = [];
  codigoUsuario: number;
  modal: NgbModalRef;

  @ViewChild('modalContasUsuario', { static: true }) modalContasUsuarioRef: ElementRef;
  @Output() contaSelecionada: EventEmitter<UsuarioEntity> = new EventEmitter();

  constructor(
    public session: SessionService,
    protected ngbModal: NgbModal,
    protected alert: AlertService
  ) { super(); }

  ngOnInit(): void {
    super.ngOnInit();
  }

  abrirContas() {
    this.contasUsuario = this.session.contasUsuario;
    this.codigoUsuario = this.session.usuario.codigoUsuario;
    this.modal = this.openModal(this.modalContasUsuarioRef, { size: 'md' });
  }

  selecionarConta() {
    const usuario = this.session.contasUsuario.find((usuario: UsuarioEntity) => usuario.codigoUsuario === this.codigoUsuario);
    this.contaSelecionada.emit(usuario);
    this.modal.close();
  }
}
