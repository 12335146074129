import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { geralConstants } from '../constants/geral.constants';
import { ContasUsuarioComponent } from '../controls/contas-usuario/contas-usuario.component';
import { AlertService } from '../shared/alert.service';
import { CommonService } from '../shared/common.service';
import { ComponentBase } from '../shared/component-base';
import { EncryptService } from '../shared/encrypt.service';
import { SessionService } from '../shared/session.service';
import { StorageService } from '../shared/storage.service';
import { CredorEntity } from './entities/credor.entity';
import { GrupoCredorEntity } from './entities/grupo-credor.entity';
import { UsuarioEntity } from './entities/usuario.entity';
import { LoginAlterarSenhaComponent } from './login-alterar-senha/login-alterar-senha.component';
import { LoginRecuperarSenhaComponent } from './login-recuperar-senha/login-recuperar-senha.component';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends ComponentBase implements OnInit {
  identificacaoUsuario: string;
  senhaAcesso: string;
  ip: string;
  showSenha: boolean;
  codigoUsuario: number;
  atualizarEmail: boolean;
  atualizarCelular: boolean;
  email: string;
  celular: string;
  codigoCelular: string;
  codigoEmail: string;
  tokenCelular: string;
  tokenEmail: string;
  validacaoDados: boolean;
  alterarSenha: boolean;
  usuario: UsuarioEntity = new UsuarioEntity();
  urlInvalida = true;
  hash: string;
  grupoCredores: GrupoCredorEntity[] = [];
  credores: CredorEntity[] = [];
  grupoSelecionado: GrupoCredorEntity;
  codigoGrupo: number;
  codigoCredor: number;
  nomeCredor: string;
  modal: NgbModalRef;
  urlCallBack: string;
  primeiroAcesso: boolean;
  userEncrypt: string;
  passEncrypt: string;
  codigoSistema: number;
  showGrupoCredor: boolean;
  contasUsuario: any[];
  contasUsuarioCredor: any[];
  ignoraGrupoCredor: boolean;

  usuarioHibrido: boolean;
  usuarioBackoffice: boolean;
  backoffice: boolean;

  @ViewChild('frmLogin', { static: true }) frmLogin: NgForm;
  @ViewChild(ContasUsuarioComponent, { static: true })
  contasUsuarioComponentRef: ContasUsuarioComponent;
  @ViewChild(LoginRecuperarSenhaComponent, { static: true })
  loginRecuperarSenhaComponentRef: LoginRecuperarSenhaComponent;
  @ViewChild('modalGrupoCredor', { static: true }) modalGrupoCredor: ElementRef;
  @ViewChild('loginAlterarSenha', { static: true })
  loginAlterarSenhaRef: LoginAlterarSenhaComponent;

  constructor(
    private loginService: LoginService,
    private session: SessionService,
    private commonService: CommonService,
    protected router: Router,
    protected alert: AlertService,
    protected storage: StorageService,
    protected ngbModal: NgbModal,
    private encripyService: EncryptService
  ) {
    super();

    this.session.clear();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.storage.data?.acessoNegado) {
      this.alert.warning(this.storage.data.acessoNegado);
      this.storage.data = null;
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    this.urlCallBack = this.commonService.isValidUrl(urlParams.get('urlcallback')) ? urlParams.get('urlcallback') : atob(urlParams.get('urlcallback'));

    const codigoSistema = this.commonService.obterCodigoSistema(this.urlCallBack);
    console.log(codigoSistema);
    this.codigoSistema = codigoSistema ? +codigoSistema : 0;
    this.session.codigoSistema = this.codigoSistema;

    this.registrarCSS();

    const dominio = this.commonService.extrairDominio(this.urlCallBack);
    this.ignoraGrupoCredor = geralConstants.sitesSemSelecaoCredor.findIndex((x) => x.url.indexOf(dominio) > -1) > -1;

    this.alterarSenha = this.router.url.indexOf('/alterarsenha') >= 0;
    if (!this.alterarSenha) {
      if (!this.urlCallBack) {
        window.location.href = environment.appFeev;
        return;
      }

      this.urlInvalida = false;
      this.commonService
        .obterIp()
        .pipe(take(1))
        .subscribe((response: any) => (this.ip = response.ip));
    } else {
      this.urlInvalida = false;
    }
  }

  acessar() {
    this.markAsTouched(this.frmLogin);

    if (this.frmLogin.valid) {
      this.autenticarIdentificacaoSenha();
    }
  }

  onkeyUp(event: any): void {
    if (event.keyCode === 13) this.acessar();
  }

  contaUsuarioSelecionada(usuario: UsuarioEntity): void {
    this.session.usuario = usuario;
    this.router.navigate(['home']);
  }

  processarUsuarioAutenticado(contasUsuario: UsuarioEntity[]): void {
    this.session.contasUsuario = contasUsuario;

    if (this.session.contasUsuario.length > 1) {
      // Abre o modal com as contas do usuário para que possa ser selecionada a conta a logar
      const primeiroUsuarioCredor = this.session.contasUsuario.find(
        (x) => x.codigoCredor > 0
      );
      if (primeiroUsuarioCredor === null)
        this.session.usuario = this.session.contasUsuario[0];
      else this.session.usuario = primeiroUsuarioCredor;

      this.contasUsuarioComponentRef.abrirContas();
    } else this.contaUsuarioSelecionada(this.session.contasUsuario[0]);
  }

  validarIdentificacaoSenha(): void {
    this.markAsTouched(this.frmLogin);

    if (this.frmLogin.valid) {
      this.loading(() =>
        this.loginService.consultarCodigoUsuarioEmailCpfCelular(
          this.identificacaoUsuario
        )
      )
        .pipe(take(1))
        .subscribe((response: any) => {
          this.usuario = new UsuarioEntity({
            codigoUsuario: response.codigoUsuario,
            usuario: this.identificacaoUsuario,
            senha: this.senhaAcesso,
            codigoCredor: response.codigoCredor,
            ip: this.ip,
            ipsLiberados: response.ipsLiberados,
            validaIpCredor: response.validaIpCredor,
            validaIpUsuario: response.validaIpUsuario,
            celular: response.celular,
            email: response.email,
            celularValidado: response.celularValidado,
            emailValidado: response.emailValidado,
          });

          this.loading(() =>
            this.loginService.consultarCodigoUsuarioSemSenha(
              this.identificacaoUsuario
            )
          )
            .pipe(take(1))
            .subscribe((codigoUsuario: number) => {
              if (codigoUsuario > 0) {
                this.alert
                  .warning(
                    'Antes de prosseguir é necessário atualizar a sua senha.'
                  )
                  .then(() => {
                    window.location.href =
                      'alterarsenha?primeiroacesso=true&usuario=' +
                      encodeURIComponent(
                        this.encripyService.encryptData(
                          this.identificacaoUsuario
                        )
                      );
                  });
              } else {
                this.codigoUsuario = response.codigoUsuario;
                this.showSenha = true;
              }
            });
        });
    }
  }

  continuarLogin(auth: any): void {
    if (auth && auth?.user && auth?.pass && auth?.urlCallback) {
      this.alterarSenha = false;

      this.identificacaoUsuario = this.encripyService.decryptData(auth.user);
      this.senhaAcesso = this.encripyService.decryptData(auth.pass);
      this.urlCallBack = auth.urlCallback;

      this.codigoSistema = this.commonService.obterCodigoSistema(this.urlCallBack);

      this.autenticarIdentificacaoSenha();
      return;
    }
  }

  filtrarContasUsuarioLogin(array: any[]): void {
    this.contasUsuario = array.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.codigoGrupoCredor === item.codigoGrupoCredor
      ))
    );
  }

  autenticarIdentificacaoSenha(): void {
    this.usuario.usuario = this.identificacaoUsuario;
    this.usuario.senha = this.senhaAcesso;
    this.usuario.codigoSistema = this.codigoSistema;

    this.call(() =>
      this.loginService.consultarCodigoUsuarioSemSenha(
        this.identificacaoUsuario
      )
    )
      .pipe(take(1))
      .subscribe((codigoUsuario: number) => {
        if (codigoUsuario > 0) {
          this.alert
            .warning('Antes de prosseguir é necessário atualizar a sua senha.')
            .then(() => {
              window.location.href = `alterarsenha?firstaccess=true&usuario=${encodeURIComponent(
                this.encripyService.encryptData(this.identificacaoUsuario)
              )}&urlcallback=${btoa(this.urlCallBack)}`;
            });
        } else {
          const consultaUsuario$ = this.call(() =>
            this.loginService.consultarCodigoUsuarioEmailCpfCelular(
              this.identificacaoUsuario
            )
          ).pipe(take(1));
          const autenticarUsuario$ = consultaUsuario$.pipe(
            switchMap((response: any) => {
              this.usuario = new UsuarioEntity({
                codigoUsuario: response.codigoUsuario,
                usuario: this.identificacaoUsuario,
                senha: this.senhaAcesso,
                codigoCredor: response.codigoCredor,
                ip: this.ip,
                ipsLiberados: response.ipsLiberados,
                validaIpCredor: response.validaIpCredor,
                validaIpUsuario: response.validaIpUsuario,
                celular: response.celular,
                email: response.email,
                celularValidado: response.celularValidado,
                emailValidado: response.emailValidado,
                codigoSistema: this.codigoSistema,
              });
              return this.loading(() =>
                this.loginService.autenticarIdentificacaoSenha(this.usuario)
              ).pipe(take(1));
            })
          );
          forkJoin([consultaUsuario$, autenticarUsuario$]).subscribe(
            ([responseUsuario, responseHash]: [any, any[]]) => {

              this.contasUsuarioCredor = responseHash;

              if (responseHash?.length > 1) {
                this.filtrarContasUsuarioLogin(responseHash);
              }
              else
                this.contasUsuario = responseHash;

              this.showGrupoCredor = this.contasUsuario?.length === 1;

              if (this.contasUsuario?.length === 1) {
                this.hash = this.contasUsuario[0].hash;
                this.usuario = responseUsuario;

                const usuario = this.contasUsuario.find(x => x.hash === this.hash);
                this.backoffice = usuario.backOffice;

                const dominio = this.commonService.extrairDominio(this.urlCallBack);
                if (geralConstants.sitesSemSelecaoCredor.findIndex((x) => x.url.indexOf(dominio) > -1) > -1) {

                  if (this.contasUsuario[0]?.backOffice) {
                    const token = btoa(
                      this.encripyService.encryptData(
                        `0|0|BackOffice|true`
                      )
                    );

                    const s = this.urlCallBack.indexOf('?') > -1 ? '&' : '?';
                    window.location.href = `${this.urlCallBack}${s}hash=${this.hash}&token=${token}`;
                  }
                  else {
                    this.loading(() => this.loginService.consultarGrupoCredorUsuario(this.hash))
                      .pipe(take(1))
                      .subscribe((response: GrupoCredorEntity[]) => {
                        this.grupoCredores = response;

                        if (this.grupoCredores.length === 1 && this.grupoCredores[0].credores.length > 0) {
                          this.ignoraGrupoCredor = false;
                          this.showGrupoCredor = true;
                          this.carregarCredores(this.grupoCredores[0].codigoGrupoCredor);
                        }
                        else {
                          this.codigoGrupo = this.grupoCredores[0]?.codigoGrupoCredor;
                          this.codigoCredor = this.grupoCredores[0]?.credores[0]?.codigoCredor;
                          this.nomeCredor = this.grupoCredores[0]?.credores[0]?.nomeCredor;

                          const token = btoa(
                            this.encripyService.encryptData(
                              `${this.codigoGrupo ?? 0}|${this.codigoCredor ?? 0}|${this.nomeCredor ?? 'BackOffice'}|${this.backoffice}`
                            )
                          );

                          const s = this.urlCallBack.indexOf('?') > -1 ? '&' : '?';
                          window.location.href = `${this.urlCallBack}${s}hash=${this.hash}&token=${token}`;
                        }
                      });
                  }
                }
                this.tratarUsuarioAutenticado();
              } else if (this.contasUsuario?.length === 0) {
                this.alert.warning('Não foi possível autenticar o usuário.');
              }
              else {
                this.showGrupoCredor = false;
                this.tratarUsuarioAutenticado();
              }
            }
          );
        }
      });
  }

  showInfo() {
    this.alert.info('Foram encontradas mais de uma conta de usuário para o e-mail/celular informado. Selecione uma conta para continuar.');
  }

  tratarUsuarioAutenticado() {
    if (this.usuario.codigoUsuario) {
      let msg = '';
      if (!this.usuario.emailValidado && !this.usuario.celularValidado) {
        msg =
          'Você precisa validar seu celular e seu email. Informe os códigos recebidos em cada um deles.';
        this.validacaoDados = true;
        this.email = ' ';
        this.celular = ' ';
        this.reenviarCodigo('todos');
      } else if (!this.usuario.emailValidado) {
        msg =
          'Para garantirmos que você é o proprietário do email informado, enviamos um código de validação. Informe o código recebido para que possamos prosseguir.';
        this.email = ' ';
        this.validacaoDados = true;
        this.reenviarCodigo('email');
      } else if (!this.usuario.celularValidado) {
        msg =
          'Para garantirmos que você é o proprietário do celular informado, enviamos um código de validação. Informe o código recebido para que possamos prosseguir.';
        this.celular = ' ';
        this.validacaoDados = true;
        this.reenviarCodigo('celular');
      }
      if (msg != '') {
        this.alert.warning(msg);
        return;
      }
    }

    if (this.contasUsuario.length === 1) {
      this.hash = this.contasUsuario[0].hash;
      this.consultarGrupoCredor();
    } else {
      this.abriModalGrupoCredor();
    }

  }

  selecionarContaUsuario(): void {
    // Consultar na lista de contas para verificar se o usuario é backoffice
    const usuario = this.contasUsuario.find(x => x.hash === this.hash);
    this.backoffice = usuario.backOffice;

    // chama a rotina de consultar grupo de credores
    this.consultarGrupoCredor(false);

    // Se o usuario for backoffice, exibe a lista de credores
    const dominio = this.commonService.extrairDominio(this.urlCallBack);
    const siteSemSelecaoCredor = geralConstants.sitesSemSelecaoCredor.findIndex((x) => x.url.indexOf(dominio) > -1) > -1;

    // Se o usuario for backoffice e o site nao permitir selecao de credor, ignora o grupo de credores
    this.ignoraGrupoCredor = usuario.descricao.toLowerCase().indexOf('backoffice') > -1 && siteSemSelecaoCredor;
    this.showGrupoCredor = !this.ignoraGrupoCredor;

    if (!this.ignoraGrupoCredor)
      this.consultarGrupoCredor(false);
  }

  consultarGrupoCredor(abrirModal = true) {
    this.loading(() => this.loginService.consultarGrupoCredorUsuario(this.hash))
      .pipe(take(1))
      .subscribe((response: GrupoCredorEntity[]) => {
        this.grupoCredores = response;

        if (this.ignoraGrupoCredor) {
          this.showGrupoCredor = false;
        } else {
          if (response.length === 1) {
            console.log(response[0]);
            this.carregarCredores(response[0].codigoGrupoCredor);
          }

          if (response.length > 0 && response.find((grupo) => grupo.credores.length >= 1) && abrirModal) {
            this.abriModalGrupoCredor();
          }

          if (response.length > 0 && response.find((grupo) => grupo.credores.length >= 1) && !abrirModal) {
            this.showGrupoCredor = true;
          }
        }
      });
  }

  autenticarGoogle(): void {
    this.loading(() => this.loginService.autenticarGoogle())
      .pipe(take(1))
      .subscribe(
        (result: any) => {
          setTimeout(() => {
            this.loading(() =>
              this.loginService.autenticarUsuarioFirebase(
                result.user.uid,
                result.user.email,
                this.codigoSistema
              )
            )
              .pipe(take(1))
              .subscribe((responseHash: any) => {
                this.contasUsuarioCredor = responseHash;

                if (responseHash?.length > 1) {
                  this.filtrarContasUsuarioLogin(responseHash);
                }
                else
                  this.contasUsuario = responseHash;

                this.showGrupoCredor = this.contasUsuario?.length === 1;

                if (this.contasUsuario?.length === 1) {
                  this.hash = this.contasUsuario[0].hash;

                  const usuario = this.contasUsuario.find(x => x.hash === this.hash);
                  this.backoffice = usuario.backOffice;

                  const dominio = this.commonService.extrairDominio(this.urlCallBack);
                  if (geralConstants.sitesSemSelecaoCredor.findIndex((x) => x.url.indexOf(dominio) > -1) > -1) {

                    if (this.contasUsuario[0]?.backOffice) {
                      const token = btoa(
                        this.encripyService.encryptData(
                          `0|0|BackOffice|true`
                        )
                      );

                      const s = this.urlCallBack.indexOf('?') > -1 ? '&' : '?';
                      window.location.href = `${this.urlCallBack}${s}hash=${this.hash}&token=${token}`;
                    }
                    else {
                      this.loading(() => this.loginService.consultarGrupoCredorUsuario(this.hash))
                        .pipe(take(1))
                        .subscribe((response: GrupoCredorEntity[]) => {
                          this.grupoCredores = response;

                          if (this.grupoCredores.length === 1 && this.grupoCredores[0].credores.length > 0) {
                            this.ignoraGrupoCredor = false;
                            this.showGrupoCredor = true;
                            this.carregarCredores(this.grupoCredores[0].codigoGrupoCredor);
                          }
                          else {
                            this.codigoGrupo = this.grupoCredores[0]?.codigoGrupoCredor;
                            this.codigoCredor = this.grupoCredores[0]?.credores[0]?.codigoCredor;
                            this.nomeCredor = this.grupoCredores[0]?.credores[0]?.nomeCredor;

                            const token = btoa(
                              this.encripyService.encryptData(
                                `${this.codigoGrupo ?? 0}|${this.codigoCredor ?? 0}|${this.nomeCredor ?? 'BackOffice'}|${this.backoffice}`
                              )
                            );

                            const s = this.urlCallBack.indexOf('?') > -1 ? '&' : '?';
                            window.location.href = `${this.urlCallBack}${s}hash=${this.hash}&token=${token}`;
                          }
                        });
                    }
                  }
                  this.tratarUsuarioAutenticado();
                } else if (responseHash?.length === 0) {
                  this.alert.warning('Não foi possível autenticar o usuário.');
                }
                else {
                  this.showGrupoCredor = false;
                  this.tratarUsuarioAutenticado();
                }
              });
          }, 0);
        },
        (error) => {
          if (error.code === 'auth/user-disabled')
            this.alert.warning('Esta conta foi desativada pelo administrador.');
        }
      );
  }

  recuperarSenha(primeiroAcesso: boolean): void {
    this.primeiroAcesso = primeiroAcesso;

    this.loginRecuperarSenhaComponentRef.abrirModaRecuperarSenha();
  }

  voltar() {
    window.location.reload();
  }

  validarCodigoEmailCelular() {
    if (this.celular && !this.codigoCelular) {
      this.alert.warning('Código de validação via SMS inválido');
      return;
    }

    if (this.email && !this.codigoEmail) {
      this.alert.warning('Código de validação via e-mail inválido');
      return;
    }

    if (
      this.codigoCelular &&
      this.codigoCelular.toUpperCase() != this.tokenCelular.toUpperCase()
    ) {
      this.alert.warning('Código de validação via SMS inválido');
      return;
    }

    if (
      this.codigoEmail &&
      this.codigoEmail.toUpperCase() != this.tokenEmail.toUpperCase()
    ) {
      this.alert.warning('Código de validação via e-mail inválido');
      return;
    }

    this.loading(() =>
      this.loginService.validarEmailCelularUsuario(this.usuario.codigoUsuario)
    )
      .pipe(take(1))
      .subscribe(() => {
        this.atualizarCelular = false;
        this.atualizarEmail = false;
        this.usuario.emailValidado = true;
        this.usuario.celularValidado = true;

        this.autenticarIdentificacaoSenha();
      });
  }

  reenviarCodigo(tipo: string) {
    tipo = tipo ? tipo : 'todos';

    this.loading(() =>
      this.loginService.enviarTokensValidacao(this.usuario, tipo)
    )
      .pipe(take(1))
      .subscribe((resp: any) => {
        this.tokenCelular = resp.tokenCelular;
        this.tokenEmail = resp.tokenEmail;
        this.validacaoDados = true;
      });
  }

  getNomeBotao(): string {
    if (!this.codigoUsuario) return 'Próximo';

    if (this.atualizarCelular && this.atualizarEmail) return 'Atualizar Dados';

    if (this.atualizarCelular && !this.atualizarEmail)
      return 'Atualizar Celular';

    if (!this.atualizarCelular && this.atualizarEmail)
      return 'Atualizar E-mail';

    return 'Acessar o sistema';
  }

  abriModalGrupoCredor(): void {
    this.modal = this.openModal(this.modalGrupoCredor);
  }

  carregarCredores(grupoCredor: number): void {
    this.grupoSelecionado = this.grupoCredores.find(
      (x) => x.codigoGrupoCredor === grupoCredor
    );
    this.codigoGrupo = this.grupoSelecionado.codigoGrupoCredor;

    this.credores = this.grupoSelecionado.credores;
    if (this.credores.length === 1) {
      this.codigoCredor = this.grupoSelecionado.credores[0].codigoCredor;
      this.nomeCredor = this.grupoSelecionado.credores[0].nomeCredor;

      if (this.grupoCredores.length === 1) this.selecionarConta();
    } else this.codigoCredor = null;
  }

  selecionarCredor(codigoCredor: number): void {
    const credor = this.grupoSelecionado.credores.find(
      (x) => x.codigoCredor === codigoCredor
    );
    this.codigoCredor = credor.codigoCredor;
    this.nomeCredor = credor.nomeCredor;
  }

  selecionarConta() {

    if (this.modal) this.modal.close();
    const s = this.urlCallBack.indexOf('?') > -1 ? '&' : '?';
    let token = '';
    if (!this.ignoraGrupoCredor) {
      if (!this.codigoGrupo) {
        this.alert.warning(
          'Selecione um grupo para prossessguir com o acesso ao sistema.'
        );
        return;
      }

      if (!this.codigoCredor) {
        this.alert.warning(
          'Selecione um credor para prossessguir com o acesso ao sistema.'
        );
        return;
      }
    }
    else {
      this.codigoGrupo = 0;
      this.codigoCredor = 0;
      this.nomeCredor = 'BackOffice';
    }

    let nomeCredor = this.backoffice && this.nomeCredor !== 'BackOffice' ? `BackOffice (${this.nomeCredor})` : this.nomeCredor;
    let hash = this.contasUsuarioCredor.find(x => x.codigoCredor == (this.backoffice ? 0 : this.codigoCredor)).hash;

    token = btoa(
      this.encripyService.encryptData(
        `${this.codigoGrupo}|${this.codigoCredor}|${nomeCredor}|${this.backoffice}`
      )
    );
    window.location.href = `${this.urlCallBack}${s}hash=${hash}&token=${token}`;
  }

  registrarCSS() {
    if (this.codigoSistema == 2) {
      window.CSS.registerProperty({
        name: "--login-gradient-1",
        syntax: "<color>",
        inherits: false,
        initialValue: "#370B60",
      });
      window.CSS.registerProperty({
        name: "--login-gradient-2",
        syntax: "<color>",
        inherits: false,
        initialValue: "#602498",
      });
      window.CSS.registerProperty({
        name: "--primary",
        syntax: "<color>",
        inherits: false,
        initialValue: "#602498",
      });
    } else if (this.codigoSistema == 7) {
      window.CSS.registerProperty({
        name: "--login-gradient-1",
        syntax: "<color>",
        inherits: false,
        initialValue: "#8e248e",
      });
      window.CSS.registerProperty({
        name: "--login-gradient-2",
        syntax: "<color>",
        inherits: false,
        initialValue: "#8e248e",
      });
      window.CSS.registerProperty({
        name: "--primary",
        syntax: "<color>",
        inherits: false,
        initialValue: "#8e248e",
      });
    } else {
      window.CSS.registerProperty({
        name: "--login-gradient-1",
        syntax: "<color>",
        inherits: false,
        initialValue: "#1F2A36",
      });
      window.CSS.registerProperty({
        name: "--login-gradient-2",
        syntax: "<color>",
        inherits: false,
        initialValue: "#00826A",
      });
    }
  }

  getYear(): number {
    const d = new Date();
    return d.getFullYear();
  }

  getPlataforma(): string {
    if (this.codigoSistema === 2) {
      return "na Minha Conta";
    }
    if (this.codigoSistema === 5 || this.codigoSistema === 6) {
      return "no Feev";
    }
    return "na plataforma";
  }
}
