import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { UsuarioEntity } from '../login/entities/usuario.entity';
import { LoginService } from '../login/login.service';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {
  constructor(
    private session: SessionService,
    private loginService: LoginService
  ) { }

  get token(): string {
    return sessionStorage.getItem('token');
  }

  renovarToken(): Observable<any> {
    if (!this.session.usuario)
      return null;

    // Renova o token baseado na autenticação por firebase
    if (this.session.usuario.googleUid)
      return this.loginService.autenticarUsuarioFirebase(this.session.usuario.googleUid, this.session.usuario.usuario, this.session.codigoSistema);

    // Renova o token por identificação e senha
    const usuario = new UsuarioEntity({
      codigoUsuario: this.session.usuario['codigoUsuario'],
      usuario: this.session.usuario['usuario'],
      senha: this.session.usuario['senha'],
      hasHash: true
    });

    return this.loginService.autenticarIdentificacaoSenha(usuario);
  }
}
