<nav id="nav-menu"
     class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
     aria-label="Ninth navbar example">
  <div class="container-xl">
    <a class="navbar-brand" href="javascript:;" [routerLink]="['/home']">
      <img alt="Logo" src="/assets/images/logofeev_color.png" width="120" />
    </a>
    <button class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <app-menu></app-menu>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle"
             href="#"
             data-bs-toggle="dropdown"
             data-bs-display="static"
             aria-expanded="false">
            <img [src]="foto"
                 alt="user"
                 width="50"
                 class="profile-pic rounded-circle"/>
          </a>
          <ul class="dropdown-menu dropdown-menu-lg-end dropdown-menu-profile">
            <li>
              <div class="dw-user-box p-3 d-flex">
                <div class="u-img">
                  <img [src]="foto"
                       alt="user"
                       class="rounded"
                       style="width: 80px" />
                </div>
                <div class="dropdown-menu-profile-content">
                  <h4 class="mb-0">{{ nomeUsuario }}</h4>
                  <p class="text-muted mb-1 font-14">
                    {{ identificacaoUsuario }}
                  </p>
                </div>
              </div>
            </li>
            <li role="separator" class="dropdown-divider"></li>
            <li class="user-list">
              <a class="px-3 py-2"
                 href="javascript:;"
                 (click)="abrirModalAlterarSenha()">Alterar a senha</a>
            </li>
            <li role="separator" class="dropdown-divider"></li>
            <li class="user-list">
              <a class="px-3 py-2" href="javascript:;" (click)="sair()">Sair</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!--Modal alterar senha-->
<app-cadastro-usuario-alterar-senha></app-cadastro-usuario-alterar-senha>
