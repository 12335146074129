import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PageItem } from './interfaces/page-item';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CrudComponent),
  multi: true
};

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.css']
})
export class CrudComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() modoEdicao = false;
  @Input() columns: string[] = [];
  @Input() alias: string[] = [];
  @Input() data: any[] = [];
  @Input() titulo: string;

  @Output() clickBotaoNovo: EventEmitter<void> = new EventEmitter();
  @Output() editar: EventEmitter<any> = new EventEmitter();
  @Output() excluir: EventEmitter<any> = new EventEmitter();
  @Output() salvar: EventEmitter<any> = new EventEmitter();
  @Output() cancelar: EventEmitter<void> = new EventEmitter();
  @Output() _change: EventEmitter<any> = new EventEmitter();

  //The internal data model
  private innerSelectValue: any = '';

  itemsPerPageOptions = [5, 10, 50, 100];
  itemsPerPage = this.itemsPerPageOptions[0];
  currentPage = 1;
  totalPages: number;
  currentSortColumn: string = "asc";
  sortDirection: 'asc' | 'desc' = "asc";
  filterQuery: string = '';
  filteredData: any[] = [];
  numberOfPagesToShow = 5;
  atualDataLength: number;

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.calculateTotalPages();
    this.atualDataLength = this.filteredData.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['data'] || !changes['data'].currentValue)
      return;

    this.filteredData = <any[]>changes['data'].currentValue;
    this.ref.detectChanges();

    if (this.atualDataLength != this.filteredData.length)
      this.refreshTable();
  }

  refreshTable(): void {
    if (!this.filteredData)
      return;

    let paginationIsOk = Math.ceil(this.data.length / this.itemsPerPage) >= this.currentPage;
    if (!paginationIsOk) {
      this.currentPage = this.currentPage - 1
    }

    this.atualDataLength = this.data.length;
    this.calculateTotalPages();
  }

  writeValue(obj: any): void {
    if (obj !== this.innerSelectValue)
      this.innerSelectValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  onChange(): void {
    this._change.emit(this.selectValue);
  }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  //get accessor
  get selectValue(): any {
    return this.innerSelectValue;
  }

  //set accessor including call the onchange callback
  set selectValue(value: any) {
    if (value !== this.innerSelectValue) {
      this.innerSelectValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  calculateTotalPages(): void {
    if (!this.filteredData)
      return;
    this.totalPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  changeItemsPerPage(newLimit: number): void {
    this.itemsPerPage = newLimit;
    this.calculateTotalPages();
    this.currentPage = 1; // Reset current page to 1
  }

  getPageData(): any[] {
    if (!this.filteredData)
      return null!;

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredData.slice(start, end);
  }

  generatePageNumbers(): PageItem[] {
    let pages: PageItem[] = [];
    let startPage: number;
    let endPage: number;

    if (this.totalPages <= this.numberOfPagesToShow) {
      startPage = 1;
      endPage = this.totalPages;
    } else {
      let maxPagesBeforeCurrentPage = Math.floor(this.numberOfPagesToShow / 2);
      let maxPagesAfterCurrentPage = Math.ceil(this.numberOfPagesToShow / 2) - 1;

      if (this.currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = this.numberOfPagesToShow;
      } else if (this.currentPage + maxPagesAfterCurrentPage >= this.totalPages) {
        startPage = this.totalPages - this.numberOfPagesToShow + 1;
        endPage = this.totalPages;
      } else {
        startPage = this.currentPage - maxPagesBeforeCurrentPage;
        endPage = this.currentPage + maxPagesAfterCurrentPage;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push({ value: i, active: false, clickable: true });
    }

    if (startPage > 1) {
      pages.unshift({ value: '<', active: false, clickable: false });
    }
    if (endPage < this.totalPages) {
      pages.push({ value: '>', active: false, clickable: false });
    }

    return pages;
  }

  onPageClick(pageValue: number | string): void {
    if (typeof pageValue === 'number') {
      this.currentPage = pageValue;
    }
  }

  sortBy(column: string): void {
    if (this.currentSortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = column;
      this.sortDirection = 'asc';
    }
    this.performSort();
  }

  private getDataType(value: any): string {
    if (typeof value === 'number') return 'number';
    if (new Date(value).toString() !== 'Invalid Date') return 'date';
    return 'string';
  }

  performSort(): void {
    if (!this.currentSortColumn || !this.sortDirection) {
      return;
    }

    this.data.sort((a, b) => {
      if (a[this.currentSortColumn] > b[this.currentSortColumn]) {
        return this.sortDirection === 'asc' ? 1 : -1;
      } else if (a[this.currentSortColumn] < b[this.currentSortColumn]) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  performSort2(column: string): void {
    const dataType = this.getDataType(this.data[0][column]);
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

    this.filteredData.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // Se algum dos valores estiver indeterminado, trate-o como o último ao ordenar
      if (valueA === undefined || valueA === null) return 1;
      if (valueB === undefined || valueB === null) return -1;

      switch (dataType) {
        case 'number':
          return this.sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
        case 'date':
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();
          return this.sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
        default:
          return this.sortDirection === 'asc'
            ? String(valueA).localeCompare(String(valueB))
            : String(valueB).localeCompare(String(valueA));
      }
    });
  }

  removeAccents(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  onFilterChange(): void {
    this.currentPage = 1;
    if (this.filterQuery) {
      this.filteredData = this.data.filter(row => {
        return this.columns.some(column =>
          this.removeAccents(String(row[column])).toLowerCase().includes(this.removeAccents(this.filterQuery).toLowerCase())
        );
      });
    } else {
      this.filteredData = [...this.data];
    }
    this.calculateTotalPages();
  }

  getColumnAlias(idx: number) {
    if (this.alias && this.alias.length > 0)
      return this.alias[idx];

    return this.columns[idx];
  }

  novo() {
    this.clickBotaoNovo.emit();
  }

  onEditar(item: any) {
    this.editar.emit(item);
  }

  onExcluir(item: any) {
    this.excluir.emit(item);
  }

  onSalvar(item: any) {
    this.salvar.emit(item);
  }

  onCancelar() {
    this.cancelar.emit();
  }
}
