import { formatNumber } from '@angular/common';
import { ElementRef, Inject, Injectable, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class MaskService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init(mask: string, inputRef: ElementRef): void {
    if (mask) {
      if (mask === 'decimal')
        ($(inputRef.nativeElement)).mask('000.000.000,00', { reverse: true });
      else if (mask === 'percent')
        ($(inputRef.nativeElement)).mask('000,00', { reverse: true });
      else if (mask === 'cpf')
        ($(inputRef.nativeElement)).mask('000.000.000-00');
      else if (mask === 'cnpj')
        ($(inputRef.nativeElement)).mask('00.000.000/0000-00');
      else if (mask === 'cpf-cnpj')
        ($(inputRef.nativeElement)).mask('000.000.000-000');
      else if (mask === 'telefone')
        ($(inputRef.nativeElement)).mask('(00) 0000-0000');
      else
        ($(inputRef.nativeElement)).mask(mask);
    }
  }

  /**
   * @description
   * Evento acionado quando ocorre modificação na model
   * @param mask
   * @param value
   * @param inputRef
   */
  formatter(mask: string, value: any, inputRef: ElementRef): any {
    if (mask && value != null && value !== '') {
      if (mask == 'decimal' || mask == 'percent') {
        value = String(value);
        const precision = 2;
        const decimalSeparator = value.substr(value.length - (Number(precision) + 1), 1);
        if (decimalSeparator == "." || decimalSeparator != ",")
          value = formatNumber(value, this.locale, '1.2-2');
      }
      else if (mask == 'cpf-cnpj') {
        value = value.toString().replace(/[^\w\s]/gi, '');
        if (value.length > 11)
          ($(inputRef.nativeElement)).mask('00.000.000/0000-00');
        else
          ($(inputRef.nativeElement)).mask('000.000.000-000');

        value = ($(inputRef.nativeElement)).masked(value);
      }
      else if (mask == 'telefone') {
        if (value.length > 5 && value[5] === "9")
          ($(inputRef.nativeElement)).mask('(00) 00000-0000');
        else
          ($(inputRef.nativeElement)).mask('(00) 0000-0000');
      }
      else if (mask == '00/00/0000') {
        if (value instanceof Date || value.indexOf('T') > -1)
          value = this.ngbDateParserFormatter.parse(moment(value).format("DD/MM/YYYY"));
      }
      else
        value = ($(inputRef.nativeElement)).masked(value);
    }

    return value;
  }

  /**
   * Evento acionado quando ocorre modificação no DOM.
   * Não realiza modificação na renderização no DOM e modifica a model.
   * Ex:
   *   DOM = 1.000,00 -> 1000.00
   * @param mask
   * @param value
   * @param inputRef
   */
  parser(mask: string, value: any, inputRef: ElementRef): any {
    if (value) {
      if (mask == 'decimal' || mask == 'percent') {
        const precision = 2;
        value = value.toString().replace(/[^\d]+/g, ""); // Retira a formatação;
        const index = value.length - precision;
        value = Number([value.slice(0, index), ".", value.slice(index)].join(""));
      }
      else if (mask == 'cpf-cnpj') {
        const cpfCnpj = value.toString().replace(/[^\w\s]/gi, '');
        if (cpfCnpj.length > 11)
          ($(inputRef.nativeElement)).mask('00.000.000/0000-00');
        else
          ($(inputRef.nativeElement)).mask('000.000.000-000');
      }
      else if (mask == 'cnpj') {
        ($(inputRef.nativeElement)).mask('00.000.000/0000-00');
      }
      else if (mask == 'cpf') {
        ($(inputRef.nativeElement)).mask('000.000.000-00');
      }
      else if (mask == 'telefone') {
        if (value.length > 5 && value[5] === "9")
          ($(inputRef.nativeElement)).mask('(00) 00000-0000');
        else
          ($(inputRef.nativeElement)).mask('(00) 0000-0000');
      }
      else if (mask == '00/00/0000')
        value = new Date(value.year, value.month - 1, value.day);
    }

    return value;
  }

  /**
   * Evento acionado quando ocorre modificação no DOM.
   * Realiza modificação na renderização no DOM e não modifica a model;
   * Ex:
   *   DOM = 0,000 -> 0,00
   * @param mask
   * @param value
   * @param oldValue
   * @param inputRef
   */
  transform(mask: string, value: any, oldValue: any, inputRef: ElementRef): any {
    if (mask == 'decimal' || mask == 'percent') {
      const val = value.toString().replace(/[^\w\s]/gi, '');
      if (Number(val) == 0) {
        value = '0,00';
        this.renderer.setProperty(inputRef.nativeElement, 'value', value);
      }
      else if (value.length == 1)
        value = "0,0" + value;
      else if (value.length == 3 || value.length == 5) {
        const numberValue = Number(value.toString().replace(',', '.'));
        if (oldValue.length <= value.length)
          value = numberValue * 10;
        else
          value = numberValue / 10;

        value = formatNumber(value, this.locale, '1.2-2');
        value = String(value);
      }
    }

    return value;
  }
}
