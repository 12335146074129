import { Injectable } from '@angular/core';

import { UsuarioEntity } from '../login/entities/usuario.entity';


@Injectable({
  providedIn: 'root'
})

export class SessionService {
  // Usuario
  get usuario(): UsuarioEntity {
    return JSON.parse(sessionStorage.getItem('usuario'));
  }

  set usuario(usuario: UsuarioEntity) {
    sessionStorage.setItem('usuario', JSON.stringify(usuario));
  }

  // Foto do login Google
  get googleFoto(): string {
    const foto = sessionStorage.getItem('googleFoto');
    return foto === "null" ? null : foto;
  }

  set googleFoto(urlFoto: string) {
    sessionStorage.setItem('googleFoto', urlFoto);
  }

  // Contas usuário
  get contasUsuario(): UsuarioEntity[] {
    return JSON.parse(sessionStorage.getItem("contasUsuario"));
  }

  set contasUsuario(usuarios: UsuarioEntity[]) {
    sessionStorage.setItem("contasUsuario", JSON.stringify(usuarios));
  }

  // Codigo Sistema
  get codigoSistema(): number {
    return parseInt(sessionStorage.getItem('codigoSistema'));
  }

  set codigoSistema(codigo: number) {
    sessionStorage.setItem('codigoSistema', codigo.toString());
  }

  clear(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
