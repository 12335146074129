import { Component, Input } from '@angular/core';
import { ItemEntity } from '../entities/item.entity';

@Component({
  selector: 'app-modelo-edicao',
  templateUrl: './modelo-edicao.component.html',
  styleUrls: ['./modelo-edicao.component.css']
})
export class ModeloEdicaoComponent {
  @Input() item: ItemEntity;
}
