export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAtehWj2WvXrYtZSC5ZmXA3H2OpBXwuNz8',
    authDomain: 'feev-dev-2023.firebaseapp.com',
    projectId: 'feev-dev-2023',
    storageBucket: 'feev-dev-2023.appspot.com',
    messagingSenderId: '450629957207',
    appId: '1:450629957207:web:8574daa5f581f38bd79512',
  },
  apiSeguranca: 'https://apisegurancahomolog.2safe.com/api/',
  apiCobranca: 'https://apicobrancahomolog.2safe.com/api/',
  apiMinhaConta: 'https://apiminhacontahomolog.2safe.com/api/',
  apiFeev: 'https://apifeev.feev.com/api/',
  codigoSistemaFeev: 5,
  appMinhaConta: 'https://minhacontahomolog.2safe.com',
  appFeev: 'https://apphomolog.feev.com.br',
  maxCodigoSistema: 7,
};
