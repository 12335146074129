import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ContasUsuarioModule } from '../controls/contas-usuario/contas-usuario.module';
import { InputModule } from '../controls/input/input.module';
import { SelectModule } from '../controls/select/select.module';
import { LayoutModule } from '../layout/layout.module';
import { LoginAlterarSenhaComponent } from './login-alterar-senha/login-alterar-senha.component';
import { LoginRecuperarSenhaComponent } from './login-recuperar-senha/login-recuperar-senha.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ContasUsuarioModule,
    LoginRoutingModule,
    SelectModule,
    InputModule,
    LayoutModule,
    NgSelectModule
  ],
  declarations: [
    LoginComponent,
    LoginRecuperarSenhaComponent,
    LoginAlterarSenhaComponent
  ]
})
export class LoginModule { }
