<ul class="navbar-nav me-auto mb-2 mb-lg-0">
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle"
       href="#"
       data-bs-toggle="dropdown"
       *ngIf="common.validarGrupoRecursos('Cadastro')">Configurações</a>
    <ul class="dropdown-menu">
      <!--Cadastros-->
      <li>
        <a class="dropdown-item"
           href="javascript:;"
           *ngIf="common.validarGrupoRecursos('Cadastro')">Cadastros &raquo;</a>
        <ul class="submenu dropdown-menu">
          <li>
            <a class="dropdown-item"
               href="javascript:;"
               [routerLink]="['/home']"
               *ngIf="common.possuiAcessoRecurso(582)">
              Cadastro inicial
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
