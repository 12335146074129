import { Component } from '@angular/core';

import { CommonService } from '../../shared/common.service';
import { ComponentBase } from '../../shared/component-base';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends ComponentBase {
  constructor(
    public common: CommonService
  ) { super(); }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
