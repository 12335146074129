import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  readonly key: string = '78442D98B4FB4167AFE5AA3BA51BF28E';

  constructor() { }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  encryptNumber(numero: number) {
    let chave = '';
    const guid = this.newGuid().replace(/[-]/g, '');
    const codigo = numero.toString().padStart(20, '0');
    let index = 0;

    for (let i = 1; i <= 10; i++) {
      const parte = codigo.substr(index, 2);
      chave += parte;

      if (i >= 7) {
        const dvparte = parseInt(parte) % (i - 1);
        chave += dvparte;
      }

      if ((i % 2) == 0 && i != 10)
        chave += guid.substr(index, 2);

      index += 2;
    }

    return chave;
  }

  decryptNumber(chave: string) {
    return parseInt(`${chave.substr(0, 2)}${chave.substr(2, 2)}${chave.substr(6, 2)}${chave.substr(8, 2)}${chave.substr(12, 2)}${chave.substr(14, 2)}${chave.substr(18, 2)}${chave.substr(21, 2)}${chave.substr(26, 2)}${chave.substr(29, 2)}`);
  }

  encryptData(data: string): string {
    let key = CryptoJS.enc.Utf8.parse(this.key);
    let encryptedBytes = CryptoJS.AES.encrypt(data, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encryptedBytes.toString();
  }

  decryptData(data: string): string {
    let key = CryptoJS.enc.Utf8.parse(this.key);

    let decryptedBytes = CryptoJS.AES.decrypt(data, key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

    return CryptoJS.enc.Utf8.stringify(decryptedBytes);
  }
}
