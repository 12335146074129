import { HttpParams } from "@angular/common/http";

import * as moment from 'moment';

export abstract class ServiceBase {
  protected httpParams(obj: any): HttpParams {
    return Object.getOwnPropertyNames(obj)
      .reduce((p, key) => {
        let valor = '';

        if (typeof obj[key] === "boolean" || (obj[key] !== null && obj[key] !== '')) {
          if (obj[key] instanceof Date)
            valor = moment(obj[key]).format('YYYY-MM-DD');
          else
            valor = obj[key];
        }

        return p.set(key, valor);
      }, new HttpParams());
  }

  protected httpArrayParams(nomeParametro: string, array: Array<any>): string {
    let arrayRequest = '';

    array.forEach(x => {
      arrayRequest += '&';

      arrayRequest += `${nomeParametro}=${x}`;
    });

    return arrayRequest;
  }
}
