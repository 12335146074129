import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InputModule } from '../controls/input/input.module';
import { CadastroUsuarioAlterarSenhaComponent } from './cadastro-usuario/cadastro-usuario-alterar-senha/cadastro-usuario-alterar-senha.component';


@NgModule({
  declarations: [
    CadastroUsuarioAlterarSenhaComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    FormsModule
  ],
  exports: [
    CadastroUsuarioAlterarSenhaComponent
  ]
})
export class CadastroModule { }
