<div class="form-group" [class.has-danger]="field.invalid && field.touched">
  <label htmlFor="select" class="form-control-label" [ngClass]="field.invalid && field.touched ? 'text-danger' : 'text-dark'" *ngIf="title">{{ title }} <span class="text-danger" *ngIf="required || required === ''">*</span></label>
  <!--Padrão-->
  <ng-select id="select"
             [(ngModel)]="selectValue"
             [items]="items"
             [bindValue]="bindValue"
             [bindLabel]="bindLabel"
             [placeholder]="placeholder"
             [required]="required"
             [disabled]="disabled"
             [readonly]="readonly"
             [loading]="loading"
             (change)="onChange()"
             [multiple]="multiple"
             [hideSelected]="hideSelected"
             [appendTo]="appendTo"
             [clearable]="clearable"
             *ngIf="!addTag">
  </ng-select>
  <!--Tag-->
  <ng-select [items]="[]"
             [addTag]="true"
             [multiple]="true"
             [selectOnTab]="true"
             [isOpen]="false"
             [(ngModel)]="selectValue"
             [placeholder]="placeholder"
             [required]="required"
             [disabled]="disabled"
             [readonly]="readonly"
             (change)="onChange()"
             *ngIf="addTag">
  </ng-select>
  <!--Complemento-->
  <small class="form-text text-secondary complemento" *ngIf="complemento">{{ complemento }}</small>
  <div class="form-control-feedback" *ngIf="validationMessage && field.invalid && field.touched">{{ validationMessage }}</div>
</div>
